.device-management {
    .heading h5 {
        display: flex;
        align-items: center;
        color: #282b3b;
        font-size: 22px;
        font-family:$primary-font;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        text-transform: capitalize;
        div {
            margin-right: 8px;
        }
    }
    .cs-checkbox {
        margin-right: 24px;
        margin-top: 6px;
        span:nth-child(2) {
            padding-top: 0;
            margin-top: -3px;
            color: #282b3b;
            font-size: 16px;
            font-weight: normal;
        }
    }
    .zone-tag {
        width: 100%;
        background: #fff;
        border-radius: 5px;
        padding: 4px 8px 4px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .left-content {
            font-family:$primary-font;
            color: #282b3b;
            font-size: 18px;
        }
    }
    .progress-bar {
        position: relative;
        display: flex;
        width: 40%;
        align-items: center;
        margin: 16px 0;
        label {
            margin: 5px 16px 0 0 !important;
            padding: 0 !important;
        }
        .MuiTypography-span {
            color: #a5a6ad;
            font-family:$primary-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 1px 0 0 16px;
            line-height: 6px;
        }
        .MuiSlider-rail {
            background: #f3f4f7;
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25) inset;
            border-radius: 50px;
            height: 14px;
        }
        .MuiSlider-track {
            border-radius: 50px 0px 0 50px;
            height: 14px;
            background: #282b3b;
        }
    }
    .upload-image {
        .upload-btn {
            width: 100%;
            height: 163px;
            padding: 0 !important;
            border-radius: 10px;
            display: block;
            background: #fff;
            border: 1px solid #cfcfcf;
            span {
                justify-content: center;
                width: 100%;
                color: #a5a6ad;
                text-align: center;
                font-family:$primary-font;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                display: flex;
            }
        }
    }
    .bottom-btn {
        button {
            min-width: 160px !important;
            svg {
                display: none;
            }
            &.active {
                background: #1c9690 !important;
                svg {
                    display: block;
                    margin-left: 10px;
                }
            }
        }
    }
    .label-color {
        display: flex;
        justify-content: end;
        padding: 12px 0;
        label {
            color: #282b3b;
            font-size: 14px;
            margin-left: 30px;
            font-style: normal;
            position: relative;
            @media screen and (max-width: 767px) {
                margin-left: 24px;
            }
            &:before {
                position: absolute;
                left: -17px;
                top: 6px;
                border-radius: 20px;
                content: "";
                width: 10px;
                height: 10px;
            }
            &.green:before {
                background: #1c9690;
            }
            &.grey:before {
                background: #a5a6ad;
            }
            &.orange:before {
                background: #ee9849;
            }
            &.red:before {
                background: #ed5c5c;
            }
            &.black:before {
                background: #282b3b;
            }
            &.blue:before {
                background: #009ee0;
            }
            &.purple:before {
                background: #7171bb;
            }
            &.pink:before {
                background: #d35691;
            }
        }
        &.text-xl {
            font-size: 15px;
            label {
                margin-right: 30px;
                margin-left: 22px;
                &:before {
                    left: -20px;
                    top: 5px;
                }
            }
        }
    }
    .datatable-cs {
        overflow-y: scroll;
        height: 45vh;
        background: #f3f4f7;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.25) inset;
        padding: 6px;
        @media screen and (max-width: 1100px) {
            overflow-x: hidden;
        }
        &.height-58{
            height: 58vh;
        }
        li {
            width: 100%;
            list-style: none;
            background: #fff;
            border-radius: 8px;
            padding: 6px 10px 6px 10px;
            margin-bottom: 10px;
            display: block;
            @media screen and (max-width: 1100px) {
                width: 100%;
            }
            .bottom-content {
                border-top: 1px solid #f0f0f2;
                display: flex;
                padding: 16px 0;
                margin-top: 10px;
                > div {
                    flex: 1;
                    padding-left: 16px;
                    font-family:$primary-font;
                }
                .left {
                    border-right: 1px solid #f0f0f2;
                }
            }

            .list-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-left: 20px;
                @media screen and (max-width: 767px) {
                    display: block;
                    padding-left: 0;
                }
                .left-content {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        padding: 0;
                        padding-bottom: 6px;
                        border-bottom: 1px dotted #cfcfcf;
                    }
                    .heading {
                        position: relative;
                        color: #303341;
                        font-size: 18px;
                        display: flex;
                        align-items: center;

                        &:before {
                            position: absolute;
                            left: -20px;
                            top: 9px;
                            border-radius: 20px;
                            content: "";
                            width: 10px;
                            height: 10px;
                        }
                        &.green:before {
                            background: #1c9690;
                        }
                        &.grey:before {
                            background: #a5a6ad;
                        }
                        &.orange:before {
                            background: #ee9849;
                        }
                        &.red:before {
                            background: #ed5c5c;
                        }
                    }
                }
                .blue-bg {
                    display: flex;
                    padding: 6px 0;
                    button.blue-btn {
                        margin-left: 16px;
                    }
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        justify-content: space-between;
                    }
                    .link-btn,
                    .link-dot {
                        color: #009ee0;
                        text-align: right;
                        font-family:$primary-font;
                        background: transparent;
                        padding: 0;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500; /* 165% */
                        text-decoration-line: underline;
                        img {
                            width: auto;
                        }
                        &:disabled {
                            color: #5e5e5f;
                        }
                    }
                    .active-text {
                        font-family:$primary-font;
                        font-size: 16px;
                        font-style: normal;
                    }
                    @media screen and (max-width: 767px) {
                        button {
                            min-width: 50px;
                        }
                    }
                }
            }

            .bottom-list {
                .list-content {
                    border-top: 1px solid #f0f0f2;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }

        &.alerts-grid {
            li .list-content {
                padding-left: 10px;
                @media screen and (max-width: 767px) {
                    padding-left: 0;
                }
                .heading {
                    text-transform: capitalize;
                    min-height: 46px;
                    display: flex;
                    align-items: center;
                    &:before {
                        display: none;
                    }
                }
                .right-content {
                    display: flex;
                    padding: 6px 0;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        justify-content: flex-start;
                    }
                    .tag {
                        min-width: 220px;
                        border-radius: 10px;
                        padding: 8px 16px;
                        text-align: center;
                        margin-right: 20px;
                        font-size: 18px;
                        font-weight: 500;
                        @media screen and (max-width: 767px) {
                            min-width: 150px;
                            font-size: 14px;
                        }
                        &.pink {
                            background: #fadcea;
                            color: #d35691;
                        }
                        &.purple {
                            background: #e8e8ff;
                            color: #7373c5;
                        }
                    }
                }
            }
        }
        &.no-scroll {
            height: 60vh;
            @media screen and (max-width: 767px) {
                height: 56vh;
            }
            li {
                width: 100%;
                .list-content .left-content {
                    width: 100%;
                    border-bottom: none;
                }
            }
        }
        &.notification {
            .cs-checkbox {
                margin-right: 14px;
            }
            .list-content {
                padding-left: 10px;
                .heading {
                    font-size: 22px !important;
                    .tag {
                        display: none;
                        border-radius: 7px;
                        background: #009ee0;
                        color: #fff;
                        padding: 1px 10px;
                        margin: 0 10px 0 2px;
                        font-size: 17px;
                    }
                    .i-icon {
                        display: flex;
                        align-items: center;
                        margin-left: 6px;
                    }
                }
                .blue-bg {
                    align-items: center;
                    font-size: 20px !important;
                    label {
                        margin-right: 10px;
                    }
                    .link-dot {
                        display: none;
                    }
                }

                &.new {
                    .heading {
                        font-weight: bold;
                        .tag {
                            font-weight: normal;
                            display: block;
                        }
                    }
                    .blue-bg {
                        label {
                            font-weight: bold;
                        }
                        .link-dot {
                            display: block;
                            text-align: center;
                        }
                    }
                }
            }
        }
        &.user-grid{
            height: 60vh;
            li .list-content{
                padding-left: 6px;
                .heading{
                    img{
                        width: 40px;
                        margin-right: 10px;
                    }
                    font-size: 24px;
                }
                .blue-bg{
                    .active{
                        margin-right: 16px;
                        margin-top: 6px;
                    }
                }
            }
        }
    }
    .grid-card {
        overflow-y: scroll;
        height: 64vh;
        background: #f3f4f7;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.25) inset;
        padding: 6px;
        width: 100%;
        .card {
            width: 32%;
            margin-right: 20px;
            background: #fff;
            border-radius: 15px;
            display: inline-grid;
            margin-bottom: 20px;
            @media screen and (max-width: 767px) {
                display: block;
                width: 100%;
                margin-right: 0;
            }
            .card-heading {
                padding: 10px;
                display: flex;
                border-bottom: 1px dotted #efefef;
                align-items: center;
                .thumbnail {
                    width: 60px;
                    height: 60px;
                    background: #efefef;
                    margin-right: 16px;
                    border-radius: 6px;
                    img {
                        width: 100%;
                    }
                }
            }
            .card-body {
                padding: 10px;
                text-align: center;
                min-height: 180px;
                position: relative;
                label {
                    font-weight: bold;
                    font-family: "Narin-Medium";
                }
                h5 {
                    font-size: 20px;
                }
                .star {
                    display: flex;
                    justify-content: center;
                }
                span {
                    img {
                        width: 40px;
                    }
                }
                button.delete {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: #282b3b;
                    img {
                        width: 18px;
                    }
                }
            }
            &:nth-of-type(3n) {
                /* for demo only */
                margin-right: 0;
            }
        }
    }
    .download-btn {
        position: fixed;
        right: 20px;
        bottom: 20px;
        background-color: #282b3b;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        @media screen and (max-width: 767px) {
            bottom: 70px;
        }
        &:focus,
        &:hover {
            background-color: #282b3b !important;
        }
    }
    .rating-details {
        background: #fff;
        border-radius: 30px;
        padding: 8px 16px;
        margin-bottom: 20px;
        h6 {
            font-size: 18px;
            font-weight: normal;
            padding: 10px 0 0 30px;
            @media screen and (max-width: 767px) {
                padding: 10px 0 0 10px;
            }
            strong {
                font-weight: bold;
            }
        }
        .rating-box {
            width: 400px;
            padding: 10px 0 0 30px;
            @media screen and (max-width: 767px) {
                width: 100%;
                padding: 10px 0 0 10px;
            }
            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 7px;
                font-size: 15px;
            }
            label {
                position: relative;
                min-width: 90px;
                &:before {
                    content: "";
                    width: 14px;
                    height: 14px;
                    border-radius: 4px;
                    background-color: #efefef;
                    position: absolute;
                    left: -50px;
                    top: 5px;
                }
            }
            span {
                min-width: 50px;
            }
            .excellent {
                label:before {
                    background-color: #1c9690;
                }
            }
            .good {
                label:before {
                    background-color: #b4bb7c;
                }
                img:nth-child(1) {
                    display: none;
                }
            }
            .okay {
                label:before {
                    background-color: #fbcc72;
                }
                img:nth-child(1),
                img:nth-child(2) {
                    display: none;
                }
            }
            .poor {
                label:before {
                    background-color: #ee9849;
                }
                img:nth-child(1),
                img:nth-child(2),
                img:nth-child(3) {
                    display: none;
                }
            }
            .bad {
                label:before {
                    background-color: #ed5c5c;
                }
                .rating {
                    img:nth-child(1),
                    img:nth-child(2),
                    img:nth-child(3),
                    img:nth-child(4) {
                        display: none;
                    }
                }
            }
            .rating {
                display: flex;
                min-width: 190px;
                padding-right: 60px;
                justify-content: end;
                > div {
                    display: flex;
                }
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }
        }
        .r-chart {
            text-align: center;
            img {
                width: auto;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    .rating-list {
        margin-top: 0;
        li {
            background: #fff;
            border-radius: 10px;
            margin-bottom: 10px;
            display: block;
            padding: 16px;
            .top-content {
                display: flex;
                width: 100%;
                justify-content: space-between;
                border-bottom: 1px solid #ebebeb;
                padding: 8px;
            }
            .content {
                display: block;
                width: 100%;
                padding: 10px 8px;
                .rating {
                    display: flex;
                    margin-left: 8px;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }
                }
                p {
                    font-style: italic;
                }
                .link {
                    min-width: 100px;
                    text-align: center;
                }
            }
        }
    }
    .mobile-device {
        padding: 30px 0;
        .frame {
            width: 100%;
            text-align: center;
            img {
                max-width: 200px;
            }
        }
        .content {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            .content-box {
                border-radius: 24px;
                background: #dbf2fb;
                width: 60%;
                text-align: center;
                padding: 30px 20px;
                h4 {
                    color: #009ee0;
                    font-size: 30px;
                    font-weight: 700;
                }
                p {
                    color: #009ee0;
                    font-size: 20px;
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    .privacy {
        padding: 30px 30px;
        background: #fff;
        border-radius: 16px;
        .heading {
            margin-top: 10px;
            text-align: center;
            margin-bottom: 30px;
        }
        h5 {
            text-align: center;
            display: block;
            font-size: 26px;
        }
        .content {
            > ul {
                padding-left: 16px;
                padding: 0;
                > li {
                    list-style: auto;
                    font-family:$primary-font;
                    margin-top: 22px;
                    font-size: 20px;
                    margin-left: 22px;
                    font-weight: bold;
                    color: #282b3b;
                }
                p {
                    font-size: 18px;
                }
            }
            ol {
                padding-left: 16px;
                padding: 0;
                li {
                    list-style: disc;
                    font-family:$primary-font;
                    font-size: 20px;
                    margin-left: 22px;
                    color: #282b3b;
                }
            }
        }
    }
    .query-list {
        margin-top: 20px;
        li {
            background: #fff;
            border-radius: 10px;
            margin-bottom: 10px;
            display: block;
            padding: 0 16px;
            .top-content {
                display: flex;
                width: 100%;
                justify-content: space-between;
                border-bottom: 1px solid #ebebeb;
                padding: 0 8px;
                @media screen and (max-width: 767px) {
                    display: block;
                }
                .left-line {
                    border-left: 1px solid #ebebeb;
                    padding-left: 10px;
                    @media screen and (max-width: 767px) {
                        border-left: none;
                        padding-left: 0;
                    }
                }
                label {
                    display: flex;
                    align-items: center;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @media screen and (max-width: 767px) {
                        padding-bottom: 0;
                    }
                }
                span {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 50%;
                    text-align: center;
                    font-style: normal;
                    line-height: 1;
                    position: relative;
                    cursor: pointer;
                    .tag {
                        display: none;
                        position: absolute;
                        bottom: -31px;
                        left: 0;
                        border-radius: 5px;
                        min-width: 90px;
                        text-align: center;
                        color: #fff;
                        background: #999;
                        font-size: 12px;
                        padding: 7px 6px;
                    }
                    &:hover {
                        .tag {
                            display: block;
                        }
                    }
                    &.red {
                        background: #ed5c5c;
                        &:hover {
                            .tag {
                                background: #ed5c5c;
                            }
                        }
                    }
                    &.orange {
                        background: #ee9849;
                        &:hover {
                            .tag {
                                background: #ee9849;
                            }
                        }
                    }
                    &.green {
                        background: #1c9690;
                        &:hover {
                            .tag {
                                background: #1c9690;
                            }
                        }
                    }
                }
                .dropdown-arrow {
                    button {
                        min-width: auto;
                        svg {
                            transform: rotate(270deg);
                            transition: all 0.3s ease-in-out;
                        }
                        &[aria-expanded="true"] {
                            svg {
                                transform: rotate(360deg);
                            }
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
            .content {
                display: block;
                width: 100%;
                padding: 10px 8px;

                .link {
                    min-width: 190px;
                    display: flex;
                }
            }
            .bottom-content {
                display: flex;
                width: 100%;
                padding: 10px 8px;
                align-items: start;
                label {
                    min-width: 64px;
                    font-weight: bold;
                }
            }
        }
    }
    .right {
        display: flex;
    }
    .right-chat {
        display: flex;
        align-items: end;
        justify-content: end;
        margin-bottom: 16px;
        .content {
            border-radius: 30px 30px 0px 30px;
            background: #dbf2fb;
            padding: 12px 20px;
            max-width: 70%;
            .list {
                display: flex;
                label {
                    margin-right: 8px;
                    font-weight: bold;
                }
            }
            .date {
                p {
                    color: #6f7074;
                }
                text-align: right;
                font-size: 16px;
                margin-top: 8px;
            }
        }
        .user {
            margin-left: 16px;
        }
    }
    .chat-section {
        height: 56vh;
        overflow-y: scroll;
    }
    .left {
        .left-chat {
            display: flex;
            align-items: end;
            justify-content: flex-start;
            margin-bottom: 16px;
            .content {
                border-radius: 30px 30px 30px 0px;
                background: #fff;
                padding: 12px 20px;
                max-width: 70%;
                .list {
                    display: flex;
                    label {
                        margin-right: 8px;
                        font-weight: bold;
                    }
                }
                .date {
                    text-align: left;
                    font-size: 16px;
                    margin-top: 8px;
                    p {
                        color: #6f7074;
                    }
                }
            }
            .user {
                margin-right: 16px;
                width: 40px;
                height: 40px;
                background: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .chat-message {
        position: relative;
        padding: 10px 0 0 0;
        textarea {
            border-radius: 20px;
            padding: 16px;
        }
        .link-btn {
            position: absolute;
            right: 10px;
            top: 16px;
        }
    }
    .device-details {
        border-radius: 20px;
        border: 1px solid #282b3b;
        overflow: hidden;
        background: #fff;
        margin-top: 20px;
        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
        .heading {
            background: #282b3b;
            position: relative;

            h3 {
                color: #fff;
                text-align: center;
                padding: 8px;
                font-weight: normal;
            }
            button {
                background: transparent;
                position: absolute;
                right: 10px;
                top: 8px;
            }
        }
        ul {
            height: 240px;
            overflow-y: scroll;
            padding: 0 6px;
            li {
                padding: 12px 10px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f1efef;
                .icon {
                    padding-top: 4px;
                    margin-right: 10px;
                    margin-left: 6px;
                }
                h4 {
                    font-weight: normal;
                    margin: -3px 0 0 0;
                }
            }
        }
    }

    .scroll {
        overflow-y: scroll;
        height: 58vh;
        margin-top: 16px;
        @media screen and (max-width: 767px) {
            height: 68vh;
        }
    }
    .device-box {
        border-radius: 31px;
        background: #fff;
        padding: 30px;
        margin-bottom: 20px;
        display: flex;
        @media screen and (max-width: 767px) {
            display: block;
            padding: 16px;
        }
        .content {
            h3 {
                color: #282b3b;
                font-family:$primary-font;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 33px;
            }
            p {
                font-family:$primary-font;
                color: #282b3b;
                line-height: normal;
            }
            h6 {
                color: #282b3b;
                font-family:$primary-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                margin: 10px 0;
                line-height: 33px;
            }
        }
        .thumbnail {
            border-radius: 22px;
            background: #fff;
            margin-right: 24px;
            box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.2);
            width: 210px;
            height: 210px;

            @media screen and (max-width: 767px) {
                margin-right: 10px;
                margin-bottom: 20px;
                width: 100px;
                height: 100px;
            }
            img {
                width: 210px;
                @media screen and (max-width: 767px) {
                    width: 100px;
                    width: 100px;
                    height: 100px;
                }
            }
        }

        .count {
            padding-top: 20px;
            margin: 20px 0;
            label {
                color: #282b3b;
                font-family:$primary-font;
                font-size: 16px;
                margin-right: 10px;
                font-style: normal;
                line-height: 33px; /* 194.118% */
            }
        }
        .number {
            span {
                cursor: pointer;
            }
            .minus {
                border-radius: 8px 0 0 8px;
            }
            .plus {
                border-radius: 0 8px 8px 0;
            }
            .minus,
            .plus {
                width: 38px;
                height: 38px;
                background: #dddee4;
                padding: 8px 5px 8px 5px;
                border: 1px solid #000;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                line-height: 18px;
                font-size: 30px;
                font-weight: bold;
            }
            input {
                height: 38px;
                width: 80px;
                text-align: center;
                font-size: 26px;
                border: 1px solid #000;
                display: inline-block;
                border-left: 0;
                border-right: 0;
                vertical-align: middle;
                @media screen and (max-width: 767px) {
                    width: 30px;
                }
            }
        }
    }
    .venue-calc {
        display: flex;
        align-items: center;
        label,
        > div {
            flex: 1;
            font-family:$primary-font;
            font-size: 16px;
            color: #282b3b;
        }
        label {
            margin-bottom: 14px;
        }
    }
    @media screen and (max-width: 767px) {
        .filter-mobile {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.2);
            z-index: 9;
            > div {
                margin: 0;
            }
            > div > div {
                display: flex;
                width: 100%;
                button,
                > div {
                    flex: 1;
                    margin-right: 0;
                    border-radius: 0;
                    box-shadow: none;
                    min-width: auto;
                    button {
                        width: 100%;
                        margin-left: 0;
                        border-radius: 0;
                        border-right: 1px solid #f3f3f3;
                    }
                }
                > div.date {
                    border-left: 1px solid #f3f3f3;
                }

                button {
                    padding: 18px 10px !important;
                }
            }
        }
    }
}
.device-modal {
    // .modal-content{
    //     padding: 0 20px;
    // }
    .device-scroll {
        overflow-y: scroll;
        padding: 0 20px;
        height: 520px;
        margin-bottom: 30px;
    }
    .device-data {
        background: #fff;
        border-radius: 20px;
        margin-bottom: 18px;
        h2 {
            text-align: center;
            padding: 10px 0;
            font-size: 28px;
            font-family:$primary-font;
            font-weight: bold;
        }
        ul {
            padding: 0 16px;
            li {
                width: 100%;
                list-style: none;
                background: #fff;
                border-radius: 8px;
                padding: 12px 10px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #f0f0f2;
                p {
                    font-family:$primary-font;
                    font-size: 20px;
                    color: #282b3b;
                }
                h6 {
                    font-family:$primary-font;
                    font-size: 20px;
                    color: #282b3b;
                    font-weight: bold;
                }
            }
        }
    }
}
.help-icon img {
    width: 24px;
    margin-right: 6px;
}
.icon-hover{
    position: relative;
    .icon{
        cursor: pointer;
    }
    .hover-effect{
        display: none;
        position: absolute;
        right: 0;
        top: 4px;
        width: 280px;
        z-index: 1;
        img{
            width: 100%;
            box-shadow: 0px 4px 24px 0px rgba(40, 43, 59, 0.20);
        }
    }
    &:hover .hover-effect{
        display: block;
    }
}