.login-main {

    .main-content {
        display: flex;
        width: 100%;
        position: relative;
        align-items: start;

        @media screen and (max-width: 990px) {
            display: block;

        }

        .main-section {
            width: 50%;
            display: flex;
            align-items: start;

            @media screen and (max-width: 990px) {
                width: 100%;
                display: block;
                align-items: start;
            }

            &.left {
                padding-left: 6%;
                padding-right: 6%;
                background: url("../../images/home-decor-4.jpg") no-repeat;
                background-size: cover !important;
                background-position: center !important;
                height: 100vh;
                align-items: center !important;

                @media only screen and (max-width: 1180px) and (min-width: 990px) {
                    padding-left: 4%;
                    padding-right: 4%;
                }

                @media screen and (max-width: 990px) {
                    padding-left: 7%;
                    padding-right: 7%;
                    padding-bottom: 80px;
                    height: auto;
                    display: none;
                }

                @media screen and (max-width: 767px) {
                    padding-left: 3%;
                    padding-right: 3%;
                    padding-bottom: 40px;
                    height: auto;
                    display: none;
                }

                .mid-section {
                    background: rgba(255, 255, 255, 0.75);
                    padding: 60px 82px 0 82px;
                    border-radius: 50px;
                    margin-bottom: 30px;
                    @media screen and (max-width: 767px) {
                        padding: 40px 32px 0 32px;
                        
                    }

                    @media only screen and (max-width: 1180px) and (min-width: 990px) {
                        padding: 40px 32px 0 32px;
                        margin-bottom: 30px;
                    }

                    .brand {
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        padding-bottom: 40px;

                        @media screen and (max-width: 767px) {
                            padding-bottom: 30px;
                        }

                        .svg {
                            width: 100%;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                        }

                        img {
                            width: 290px;

                            @media screen and (max-width: 767px) {
                                width: 240px;
                            }
                            @media screen and (min-width: 2000px) {
                                width: 360px;
                             }
                        }
                    }

                    h4 {
                        color: $primary-color;
                        font-size: 34px;
                        font-style: normal;
                        margin-bottom: 16px;
                        text-align: center;
                        font-weight: normal;

                        line-height: 48px;

                        @media screen and (max-width: 767px) {
                            font-size: 26px;
                            line-height: 34px;
                        }

                        span {
                            font-size: 50px;
                        }
                        @media screen and (min-width: 2000px) {
                           font-size: 50px;
                           line-height: 64px;
                        }
                    }


                    .login-btn {
                        padding-bottom: 60px;
                        padding-top: 40px;
                        text-align: center;
                        display: flex;
                        justify-content: center;

                        @media screen and (max-width: 767px) {
                            padding-bottom: 40px;
                            padding-top: 20px;
                        }

                        .btn-login {
                            cursor: pointer;
                            background: transparent;
                            padding: 16px 12px;
                            min-width: 220px;
                            border: 1px solid $primary-color;
                            border-radius: 70px;
                            color: #fff;
                            font-weight: 600;
                            color: $primary-color;
                            font-size: 18px;
                            align-items: center;
                            @media screen and (min-width: 2000px) {
                                font-size: 24px;
                                padding: 16px 24px;
                                min-width: 260px;
                                svg{
                                    width: 20px;
                                    height: 21px;
                                }
                             }
                        }

                    }
                }
            }

            &.right {
                padding-right: 8%;
                background: #F5F5F5;
                height: 100vh;

                @media screen and (min-width: 1500px) {
                    padding-right: 8%;
                }

                @media screen and (max-width: 990px) {
                    padding: 20px;
                    padding-bottom: 60px;
                    min-height: 100vh;
                    height: auto;
                }

                @media only screen and (max-width: 1180px) and (min-width: 768px) {
                    padding-right: 8%;
                }

                .mid-section {
                    padding: 60px 0 10px 8%;
                    width: 100%;

                min-height: 100vh;
                align-items: center;
                    @media only screen and (max-width: 1400px) and (min-width: 768px) {
                        padding: 26px 0 10px 6%;
                    }

                    @media screen and (max-width: 767px) {
                        padding: 14px 0px 10px 0px;
                    }
                }

                h3 {
                    font-size: 22px;
                    color: $primary-color;
                    font-weight: normal;

                    @media screen and (max-width: 767px) {
                        font-size: 22px;
                    }
                    @media screen and (min-width: 2000px) {
                        font-size: 32px;
                     }
                }

                h2 {
                    font-size: 40px;
                    font-weight: 500;
                    color: $primary-color;
                    font-weight: normal;
                    line-height: normal;
                    margin-bottom: 16px;
                    margin-top: 6px;
                    @media screen and (max-width: 767px) {
                        font-size: 30px;
                    }
                    @media screen and (min-width: 2000px) {
                        font-size: 70px;
                     }
                }

                p {
                    color: $primary-color;
                    font-size: 18px;
                    opacity: 0.5;
                    margin-bottom: 50px;

                    @media screen and (max-width: 767px) {
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: 2000px) {
                        font-size:26px;
                     }
                }

                .content-section {
                    width: 100%;
                }

                .login-form {
                    width: 100%;

                    label {
                        color: $primary-color;
                        font-size: 18px;
                        @media screen and (min-width: 2000px) {
                            font-size: 24px;
                         }
                    }

                    .form-label {
                        width: 100%;
                        min-height: 60px;
                        margin-bottom: 20px;
                        position: relative;

                        button {
                            position: absolute;
                            right: 4px;
                        }

                        >div>div {
                            margin: 0;
                        }

                        :before {
                            display: none;
                        }

                        :after {
                            left: 0;
                            right: 0;
                            bottom: 1px;
                            border-bottom: 4px solid;
                            border-color: $primary-color !important;
                            border-radius: 0 0 8px 8px;
                        }

                        input {
                            border-radius: 9px;
                            border: 1.25px solid #D9D9D9 !important;
                            background: #FFF !important;
                            height: 34px;
                            @media screen and (min-width: 2000px) {
                                font-size: 24px;
                                height: 50px;
                             }
                        }

                        @media screen and (max-width: 767px) {
                            min-height: 60px;
                        }

                        @media only screen and (max-width: 1400px) and (min-width: 768px) {
                            margin-bottom: 20px;
                        }

                        input {
                            padding: 10px 14px;
                        }

                        label {
                            color: $primary-color;
                            font-weight: 700;
                        }
                    }

                    .forgot-pwd {
                        color: $primary-color;
                        text-align: right;
                        font-family: $primary-font;
                        font-size: 16px;
                        font-style: normal;
                        margin: -10px 0 0 0;
                        font-weight: 500;
                        line-height: normal;
                        text-decoration: underline;

                        @media only screen and (max-width: 1400px) and (min-width: 768px) {
                            margin: -18px 0 0 0;
                        }

                        @media screen and (max-width: 767px) {
                            margin: -28px 0 0 0;
                        }
                        @media screen and (min-width: 2000px) {
                            font-size: 24px;
                         }
                    }

                    .login-btn {
                        text-align: center;
                    }

                    .btn-login {
                        margin: 60px 0 0 0;
                        border-radius: 10px;
                        font-family: $primary-font;
                        padding: 12px 12px;
                        min-width: 238px;
                        min-height: 60px;
                        color: #fff;
                        background: $primary-color;
                        font-size: 18px;
                        border: none;
                        cursor: pointer;

                        @media screen and (max-width: 767px) {
                            width: 140px;
                            margin: 60px 0 80px 0;
                        }
                        @media screen and (min-width: 2000px) {
                            font-size: 26px;
                            min-width: 260px;
                            min-height: 80px;
                         }
                    }
                }
            }

            .mid-section {
                // height: 710px;
                display: flex;

            }
        }
    }
}
.modal-main.cs-modal{
    .modal-content .modal-footer{
        justify-content: space-around;
        @media screen and (max-width: 800px) {
           display: block;
           button{
            margin-bottom: 10px;
            width: 100%;
           }

        }
    }
}