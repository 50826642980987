.optdropdown {
    .forminput {
        height: 40px;
    }
    img {
        width: 36px !important;
    }
    .blank-img {
        width: 40px;
    }
}

.select_gestures {
    img {
        width: 50px !important;
    }
    .gesture-checkbox {
        display: flex;
        position: relative;
        z-index: 0;
        > label {
            justify-content: center;
            display: flex;
        }
    }
}
.billing-info {
    .cs-grid {
        .MuiPaper-rounded {
            border-radius: 20px;
            box-shadow: none;
        }
        .delete-btn {
            position: absolute;
        }
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 40px !important;
    }
    .card-layout {
        display: grid;
        overflow: hidden;
        border-radius: 20px;
    }
    .form-main {
        display: contents;
        button {
            border-radius: 0 0 0 0;
        }
    }
    .form-content {
        overflow-y: scroll;
        height: 100%;
    }
    @media screen and (max-width: 767px) {
        .add-question {
            position: fixed;
            bottom: 50px;
            right: 0;
            background: transparent;
            padding: 0 !important;
            > div {
                > div {
                    height: auto;
                    padding: 0;
                }
                background: transparent;

                box-shadow: none;
                svg {
                    width: 80px;
                }
                h6 h6 {
                    display: none;
                }
            }
        }
    }
    .add-question {
        > div {
            border-radius: 20px;
            box-shadow: none;
        }
    }
}
@media screen and (max-width: 767px) {
    .button-fixed {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff !important;
        box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.2);
        left: 0;
        z-index: 9;
        padding: 12px 4px;
        div {
            margin: 0 !important;
            padding: 0 4px;
            width: 50%;
            button {
                min-width: 100%;
                font-size: 14px;
                padding: 14px 0px;
                filter: drop-shadow(0px 0px 14px rgba(40, 43, 59, 0.2));
                svg {
                    display: none;
                }
            }
        }
    }
}
.survey-details {
    .MuiPaper-elevation {
        background: transparent;
        box-shadow: none;
    }
    .upload-logo {
        .upload-btn {
            width: 127px;
            height: 127px;
            padding: 0 !important;
            border-radius: 10px;
            display: block;
            border: 1px solid #cfcfcf;
            background: #fff;
            span {
                justify-content: center;
                width: 100%;
                font-weight: normal;
                color: #a5a6ad;
                text-transform: capitalize;
                display: flex;
            }
        }
    }
    .survey-dropdown {
        width: 100%;
        > div {
            margin-top: 2px;
            margin-bottom: 8px;
            @media screen and (max-width: 767px) {
                margin-bottom: 8px;
            }
        }
    }
}
.deploy-survey {
    .heading {
        h6 {
            color: #282b3b !important;
            font-family: $primary-font;
            font-size: 22px !important;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 33px;
            margin-bottom: 0;
            @media screen and (max-width: 767px) {
                font-size: 18px !important;
            }
        }
    }
    .btn-second {
        margin-left: 20px;
        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
    .label-color {
        padding: 12px 0;
        @media screen and (max-width: 767px) {
            overflow-x: scroll;
        }
        .label-box {
            display: flex;
            justify-content: end;
            @media screen and (max-width: 767px) {
                width: 100%;
                display: contents;
                text-wrap: nowrap;
            }
        }
        label {
            color: #282b3b;
            font-size: 14px;
            margin-left: 30px;
            font-style: normal;
            position: relative;
            @media screen and (max-width: 767px) {
                margin-left: 24px;
            }
            &:before {
                position: absolute;
                left: -17px;
                top: 7px;
                border-radius: 20px;
                content: "";
                width: 10px;
                height: 10px;
            }
            &.green:before {
                background: #1c9690;
            }
            &.grey:before {
                background: #a5a6ad;
            }
            &.orange:before {
                background: #ee9849;
            }
            &.red:before {
                background: #ed5c5c;
            }
            &.black:before {
                background: #282b3b;
            }
            &.blue:before {
                background: #009ee0;
            }
            &.purple:before {
                background: #7171bb;
            }
        }
        &.text-xl {
            font-size: 15px;
            label {
                margin-right: 30px;
                margin-left: 22px;
                &:before {
                    left: -20px;
                    top: 5px;
                }
            }
        }
    }
    .datatable-cs {
        overflow-y: scroll;
        height: 45vh;
        background: #f3f4f7;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.25) inset;
        padding: 6px;
        // @media screen and (max-width: 1200px) {
        //     overflow-x: scroll;
        // }
        li {
            width: 100%;
            list-style: none;
            background: #fff;
            border-radius: 8px;
            padding: 6px 14px;
            margin-bottom: 10px;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
            .main-list {
                width: 100%;
                .bottom-content {
                    border-top: 1px solid rgb(40 43 59 / 9%);
                    padding: 10px;
                    transition: 0.3s;
                    label {
                        color: #303341;
                        font-size: 18px;
                        font-family:$primary-font;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                    .question {
                        display: grid;
                        overflow: hidden;
                        transition: 0.3s;
                    }
                    .height {
                        // height: 100px;
                    }
                }
            }
            .list-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 767px) {
                    display: block;
                }
                .left-content {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        padding: 10px 0 12px 0;
                        border-bottom: 1px dotted #cfcfcf;
                    }
                    .heading {
                        position: relative;
                        color: #303341;
                        font-size: 18px;
                        text-transform: capitalize;
                        @media screen and (max-width: 767px) {
                            margin-left: -17px;
                        }
                        &.lg {
                            font-size: 20px;
                            cursor: pointer;
                        }
                        &:before {
                            position: absolute;
                            left: -17px;
                            top: 10px;
                            border-radius: 20px;
                            content: "";
                            width: 10px;
                            height: 10px;
                        }
                        &.green:before {
                            background: #1c9690;
                        }
                        &.grey:before {
                            background: #a5a6ad;
                        }
                        &.orange:before {
                            background: #ee9849;
                        }
                        &.red:before {
                            background: #ed5c5c;
                        }
                    }
                }
                .blue-bg {
                    border-radius: 5px;
                    background: #dff1f9;
                    display: flex;
                    padding: 6px 0;
                    min-width: 580px;
                    @media screen and (max-width: 767px) {
                        min-width: 410px;
                    }
                    @media only screen and (max-width: 1200px) and (min-width: 768px) {
                        min-width: 450px;
                    }
                    > div {
                        min-width: 50%;
                        font-size: 16px;
                        padding-left: 12px;
                        color: #009ee0;
                    }
                    .left-text {
                        border-right: 1px solid #009ee0;
                    }
                    span {
                        font-weight: bold;
                    }
                }
                .right-content {
                    display: flex;
                    padding: 8px 0;
                    margin-right: 6px;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        button.secondary-btn {
                            flex: 1;
                            min-width: 110px;
                        }
                    }
                    .tag {
                        border-radius: 5px;
                        min-width: 120px;
                        padding: 8px 10px;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        @media screen and (max-width: 767px) {
                            flex: 1;
                            min-width: 80px;
                        }
                        &.green {
                            background: #e8efef;
                            color: #679e9c;
                        }
                        &.orange {
                            color: #ee9849;
                            background: #fce3cd;
                        }
                        &.blue {
                            background: #dff1f9;
                            color: #009ee0;
                        }
                        &.purple {
                            background: #e5e5fe;
                            color: #7171bb;
                            margin-left: 0;
                        }
                    }
                    .remove-width {
                        margin-left: 24px;
                        margin-right: 4px;
                        min-width: auto;
                        @media screen and (max-width: 767px) {
                            flex: 1;
                        }
                        img {
                            margin: 0 0 3px 0;
                        }
                    }
                    > span {
                        color: #303341;
                        font-size: 15px;
                        font-family:$primary-font;
                        font-weight: normal;
                        margin-right: 16px;
                    }
                }
                .tag-main {
                    display: flex;
                    padding: 3px 0;
                    .tag-item {
                        border-radius: 5px;
                        min-width: 120px;
                        padding: 8px 10px;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        margin-left: 16px;
                        &.green {
                            background: #e8efef;
                            color: #679e9c;
                        }
                        &.blue {
                            background: #dff1f9;
                            color: #009ee0;
                        }
                        &.purple {
                            background: #e5e5fe;
                            color: #7171bb;
                            margin-left: 0;
                        }
                    }
                }
                .cs-checkbox {
                    width: 40px;
                    margin: 6px 26px 0 -7px;
                    @media only screen and (max-width: 1400px) and (min-width: 768px) {
                        margin: 5px 26px 0 -7px;
                    }
                }
            }
        }
        &.deploy-mobile {
            li {
                .list-content {
                    .cs-checkbox {
                        max-width: 52px;
                    }
                    .tag-main {
                        display: flex;
                        @media screen and (max-width: 767px) {
                        display: block;
                        }
                        .tag-item {
                            margin-left: 12px;
                            margin-top: 8px;
                            font-size: 14px;
                            text-transform: capitalize;
                            @media screen and (max-width: 767px) {
                                margin-left: 0;
                                }
                        }
                    }
                }
            }
        }
        &.override-scroll{
            overflow-y: hidden;
        height: auto;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        }
    }
    .custom-datatable {
        margin-top: 12px;
        overflow-y: scroll;
        height: 66vh;
        background: #f3f4f7;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.25) inset;
        padding: 6px;
        @media screen and (max-width: 767px) {
            width: 100%;
            overflow-x: scroll;
        }
        table {
            border-collapse: inherit;
            width: 100%;
            @media screen and (max-width: 767px) {
                width: 800px;
            }
        }
        table thead {
            display: none;
        }
        table tbody {
            background-color: #f0f2f5;
            border-radius: 0 !important;
        }
        table tbody tr {
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 10px;
            width: 100%;
            display: inline-table;
            &.disabled {
                border: 1px solid #282b3b;
            }
            .cs-checkbox {
                margin: 0px 0 -29px 0;
                flex: 0;
            }
            .heading {
                color: #303341;
                font-family:$primary-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                min-width: 420px;
                margin-left: 0;
                text-transform: capitalize;
                @media only screen and (max-width: 1400px) and (min-width: 768px) {
                    font-size: 16px;
                    margin-left: -10px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    min-width: 230px;
                }
            }
            .time {
                color: #303341;
                font-size: 15px;
                font-family:$primary-font;
                font-weight: normal;
            }
            a {
                color: #303341;
                font-size: 16px;
                @media only screen and (max-width: 1400px) and (min-width: 768px) {
                    font-size: 14px;
                }
            }
            td:last-child {
                padding-left: 0;
                padding-right: 10px;
                width: 60px;
            }
            td:nth-child(3) {
                padding-left: 0;
                padding-right: 10px;
                width: 60px;
            }
            td {
                padding: 10px 14px;
                @media only screen and (max-width: 1400px) and (min-width: 768px) {
                    padding: 8px 12px;
                }
                @media screen and (max-width: 767px) {
                    padding: 8px 10px;
                }
            }
        }
    }
    .custom-accordion {
        margin-top: 10px;
        > div {
            margin-bottom: 10px;
            background: transparent;
            box-shadow: none;
            &:before {
                display: none;
            }
        }
        .accordion-panel {
            position: relative;
            background: #fff;
            border-radius: 10px;
            > div {
                align-items: center;
                margin: 0;
            }
            .heading {
                color: #282b3b;
                text-align: center;
                font-size: 30px;
                font-style: normal;
                line-height: 33px; /* 110% */
                text-transform: uppercase;
                font-family: "Narin-Black";
                font-weight: 800;
                border-right: 1px dotted rgba(165, 166, 173, 0.5);
                display: flex;
                align-items: center;
                padding: 16px 20px 16px 8px;
                margin: 0 20px 0 0;
                @media screen and (max-width: 767px) {
                    font-size: 20px;
                    padding: 16px 10px 16px 2px;
                    margin: 0 10px 0 0;
                    line-height: 23px; /* 110% */
                }
            }
            .secondaryHeading {
                color: #282b3b;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                font-family: $primary-font;
                line-height: 33px; /* 183.333% */
            }
            &:after {
                content: "";
                background: url(../../images/icons/expand.png) no-repeat;
                width: 18px;
                height: 20px;
                position: absolute;
                right: 12px;
                top: 21px;
                transition: all 0.3s ease-in;
            }
            &.Mui-expanded:after {
                transform: rotate(90deg);
                right: 16px;
                top: 24px;
            }
        }
        .accordion-detail {
            padding: 20px 10px 20px 50px;
            display: block;
            > div {
                display: flex;
                align-items: center;
            }
            .main-content {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                @media screen and (max-width: 767px) {
                    display: flex;
                }
                .left {
                    display: flex;
                }
                &:first-child {
                    .content:before {
                        height: 49px;
                        top: -19px;
                    }
                }
                .question-text {
                    color: #282b3b;
                    text-align: center;
                    padding: 8px 16px;
                    border-radius: 10px;
                    min-width: 60px;
                    text-align: center;
                    font-family: "Narin-Black";
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 33px; /* 132% */
                    text-transform: uppercase;
                    background: #fff;
                    margin-left: 10px;
                    @media screen and (max-width: 767px) {
                        width: 70px;
                        margin-top: 10px;
                        font-size: 18px;
                    }
                }
                .patient-text {
                    color: #7171bb;
                    text-align: center;
                    padding: 8px 16px;
                    border-radius: 10px;
                    min-width: 220px;
                    text-align: center;
                    font-family:$primary-font;
                    font-size: 16px;
                    font-style: normal;
                    line-height: 33px; /* 132% */
                    text-transform: capitalize;
                    background: #e5e5fe;
                    margin-left: 10px;
                    @media screen and (max-width: 767px) {
                        width: 220px;
                        margin-top: 10px;
                        padding: 6px 16px;
                    }
                }
            }
            .content {
                background: #dbf2fb;
                border-radius: 10px;
                margin-right: 10px;
                min-width: 320px;
                display: flex;
                align-items: center;
                position: relative;
                @media screen and (max-width: 767px) {
                    min-width: 180px;
                }
                &:before {
                    content: "";
                    width: 21px;
                    height: 71px;
                    position: absolute;
                    left: -20px;
                    top: -43px;
                    border-radius: 0 0 0 16px;
                    border-left: 1px dotted #009ee0;
                    border-bottom: 1px dotted #009ee0;
                }
            }
            .heading {
                color: #009ee0;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: 33px; /* 132% */
                text-transform: uppercase;
                border-right: 1px dotted #009ee0;
                display: flex;
                align-items: center;
                padding: 8px 24px 8px 24px;
                margin: 0 20px 0 0;
            }
            .secondaryHeading {
                color: #009ee0;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 33px;
            }
            button {
                padding: 0;
                background: none;
            }
        }
    }
    .sort-btn {
        .white-sort {
            display: none;
        }
        &:hover {
            .black-sort {
                display: none;
            }
            .white-sort {
                display: block;
            }
        }
        &:focus:not(:hover) {
            background-color: #fff;
        }
    }
    @media screen and (max-width: 767px) {
        .filter-mobile {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.2);
            z-index: 9;
            > div {
                margin: 0;
            }
            > div > div {
                display: flex;
                width: 100%;
                button,
                > div {
                    flex: 1;
                    margin-right: 0;
                    border-radius: 0;
                    box-shadow: none;
                    button {
                        width: 100%;
                        margin-left: 0;
                        border-radius: 0;
                        border-right: 1px solid #f3f3f3;
                    }
                }
                button {
                    padding: 18px 10px !important;
                }
            }
        }
    }
}
.survey-confirmation {
    min-height: 80vh;
    .content-section {
        text-align: center;
        padding: 60px 0 30px 0;
        h3 {
            color: #282b3b;
            font-size: 28px;
            margin-top: 20px;
        }
    }
}

.alert-action {
    .alert-heading {
        background: #282b3b;
        position: absolute;
        border-radius: 20px 20px 0px 0px;
        height: 58px;
        text-align: center;
        display: flex;
        align-items: center;
        width: 100%;
        z-index: 9;
        button {
            position: absolute;
            left: 10px;
            top: 10px;
            background: transparent;
            padding: 0;
            min-width: 34px;
            border: none;
        }
        .heading {
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-family:$primary-font;
            font-weight: 700;
            line-height: 33px;
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    .billing-info .card-layout {
        display: block;
    }
    .scroll-content {
        height: 91%;
        overflow-y: auto;
        padding: 60px 0 0 0;
        @media screen and (max-width: 767px) {
            height: auto;
        }
    }
    ul {
        padding: 0 30px;
        li {
            padding: 30px 0 30px 0;
            border-bottom: 1px solid #f0f0f2;
            display: block;
            .alert-list-heading {
                width: 100%;
                .thumbnail {
                    margin-right: 24px;
                    padding-top: 4px;
                    img {
                        width: 38px;
                    }
                }
            }

            .content-section {
                width: 100%;
                padding-top: 20px;
                fieldset legend span {
                    display: none;
                }
                .forminput {
                    fieldset {
                        top: 0;
                    }
                }
            }
        }
    }
}
.cs-dropdown fieldset legend span {
    display: none;
}



.brand-details {
    .upload-logo {
        .upload-btn {
            width: 141px;
            height: 141px;
            padding: 0 !important;
            border-radius: 10px;
            display: block;
            span {
                justify-content: center;
                width: 100%;
                font-weight: normal;
                color: #a5a6ad;
                text-transform: capitalize;
                display: flex;
            }
        }
    }
    .survey-dropdown {
        width: 100%;
        > div {
            margin-top: 2px;
            margin-bottom: 8px;
            @media screen and (max-width: 767px) {
                margin-bottom: 8px;
            }
        }
    }
}
