.xl-container .datatable {
    overflow-y: scroll;
    height: 64vh;
    background: #fff;
    border-radius: 4px;
    padding: 10px;

    @media screen and (max-width: 1100px) {
        overflow-x: hidden;
    }

    li {
        width: 100%;
        list-style: none;
        padding: 6px 10px 6px 10px;
        margin-bottom: 10px;
        display: block;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);

        @media screen and (max-width: 1100px) {
            width: 100%;
        }

        .flex {
            display: flex;
        }

        .bottom-content {
            border-top: 1px dotted #0000008c;
            width: 100%;
            padding: 10px 0 0px 0;
            margin-top: 10px;
            font-size: 15px;
            display: flex;

            label {
                margin-right: 10px;
                font-weight: bold;
            }

            &.block {
                display: block;

                .content {
                    margin-bottom: 0;
                }
            }

            .content {
                display: flex;
                min-width: 220px;
                margin-bottom: 10px;
                padding: 10px 0;
                color: $primary-color;
                border-right: 1px dotted #cfcfcf;
                margin-right: 10px;
                padding-right: 10px;

                &:last-child {
                    border-right: none;
                }

                .left {
                    margin-right: 10px;

                }
            }

            &.reason {
                display: block;
            }
        }

        .list-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 4px;
            min-height: 56px;
            align-items: center;

            .left-content {
                display: flex;
                align-items: center;

                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding: 0;
                    padding-bottom: 6px;
                    border-bottom: 1px dotted #cfcfcf;
                    padding-left: 0;
                }

                .heading {
                    position: relative;
                    color: $primary-color;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    align-items: center;
                    // text-transform: capitalize;

                    .cs-checkbox span:nth-child(2) {
                        font-weight: bold;
                    }

                    >span {
                        border-radius: 50%;
                        margin-right: 10px;
                        display: flex;
                        overflow: hidden;

                        img {
                            width: auto;

                        }
                    }

                    &.dot {
                        padding-left: 10px;

                        &:before {
                            position: absolute;
                            left: -10px;
                            top: 11px;
                            border-radius: 20px;
                            content: "";
                            width: 10px;
                            height: 10px;
                        }

                        &.green:before {
                            background: #1c9690;
                        }

                        &.grey:before {
                            background: #a5a6ad;
                        }

                        &.orange:before {
                            background: #ee9849;
                        }

                        &.red:before {
                            background: #ed5c5c;
                        }
                    }


                }
            }

            .blue-bg {
                display: flex;
                padding: 6px 0;
                .box{
                    min-width: 150px;
                }
                .dot-link{
                    margin-top: 3px;
                   >div{
                    color: $primary-color;
                    min-height: auto;
                    padding: 7px 10px;
                    margin-left: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    min-width: 130px;
                   }
                    
                    fieldset{
                        border: 1px solid $primary-color;
                        border-radius: 3px;
                   
                    }
                }
                .cs-radio {
                    min-width: 130px;
                }

                .cs-radio span {
                    color: $primary-color !important;
                }

                >.info-tag {
                    span {
                        font-size: 14px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        min-width: 160px;
                        padding: 4px;
                    }

                    &.pink {
                        background: #FBE5E2;
                        color: #592B24;
                        border-radius: 33px;
                    }

                    &.yellow {
                        background: #FCF3E0;
                        color: #7B5F23;
                        border-radius: 33px;
                    }

                    &.blue {
                        background: #F0F8FF;
                        color: #7B5F23;
                        border-radius: 33px;
                    }
                }

                .primary-btn {
                    margin-left: 16px;

                    &.green {
                        background: #0CAD6A;
                    }

                    &.red {
                        background: #D52649;
                    }
                }

                >.tag {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: -30px;
                        top: 14px;
                        border-radius: 20px;
                        content: "";
                        width: 11px;
                        height: 11px;
                        background: #32A072;
                    }
                }

                .grid-btn {
                    border-radius: 3px;
                    border: 1px solid $primary-button;
                    color: $primary-color;
                    min-height: auto;
                    padding: 5px 10px;
                    margin-left: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    min-width: 130px;
                    text-align: center;
                    text-align: center;
                    margin-top: 3px;
                    &.approved {
                        border-color: #0CAD6A;
                        color: #0CAD6A;
                        margin-left: 16px;
                    }

                    &.rejected {
                        border-color: #D52649;
                        color: #D52649;
                        margin-left: 16px;
                    }

                    &.applied {
                        border-color: #EFA811;
                        color: #EFA811;
                        margin-left: 16px;
                    }
                }

                button.link-btn {
                    padding: 0;
                    min-width: 30px;

                    img {
                        width: auto;
                    }
                }

                button.blue-btn {
                    margin-left: 16px;
                }

                .active-text {
                    color: $primary-button;
                    font-size: 14px;
                    min-width: 60px;
                    text-align: right;
                }



                @media screen and (max-width: 767px) {
                    button {
                        min-width: 50px;
                    }
                }
            }
        }

        .bottom-list {
            .list-content {
                border-top: 1px solid #f0f0f2;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50vh;
        text-align: center;

        span {
            color: rgba(0, 158, 224, 0.18);
            text-align: center;
            font-size: 85px;
            font-style: normal;
            font-weight: 700;
        }

        h4 {
            font-weight: bold;
            font-size: 28px;
            margin-top: 0;
        }
    }


}

.active-text {

    font-size: 14px;
}

.card-layout {
    .form-main {
        min-height: 600px;
        .box-main{
            min-height: 120px;
        }
        .days-input {
            display: flex;
            border-radius: 5px;
            overflow: hidden;
            align-items: center;
            background: #fff;
            span{
            font-size: 16px;
            min-width: 100px;
            text-align: center;
           
            }
            button {
                padding: 9px;
                background: $primary-color;
                border: none;
                font-size: 22px;
                width: 46px;
                color: #fff;
                cursor: pointer;
            }
        }

        .edit-contract-section {
            margin-bottom: 16px;

            .label {
                border-radius: 5px;
                background: #D2DDE9;
                padding: 10px;
                margin-top: 21px;
                font-size: 16px;
                width: 100%;
                display: block;
            }

            .secondary-btn {
                margin-top: 20px;
                min-width: 100%;
                width: 100%;
            }

            .delete-btn {
                background-color: #fff;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                min-width: auto;
                margin-top: 19px;
            }
        }

        .cs-dropdown {
            .MuiSelect-select {
                display: flex;
                position: relative;
                z-index: 1;

            }

            &.icon .form-select:after {
                position: absolute;
                right: 0;
                z-index: 0;
            }
        }

        .label-tag {
            border-radius: 40px;
            background: rgba(22, 39, 59, 0.10);
            display: inline-block;
            padding: 0 10px 4px 10px;
            margin: 6px 0 0 0;

            span {
                padding: 4px 6px;
                color: $primary-color;
                font-size: 15px;
            }
        }

        .color-box {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0 6px 0 0;
        }

        .tag-view .box .tag-item {
            justify-content: center;

            span {
                text-align: center;
            }
        }
    }
}

.no-data {
    height: 64vh;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-bottom: 60px;

    span {
        color: rgb(24 38 60 / 27%);
        font-weight: bold;
        font-size: 85px;

    }

    h3 {
        font-size: 16px;
        font-weight: normal;

    }
}
.tab-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tab-main{
        width: 80%;
    }
}
.tab-main.MuiBox-root {
    background: transparent;

    >div {
        background: transparent;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .MuiTabs-fixed {
        width: 50%;
        flex: none;
        background: transparent !important;

        @media screen and (max-width: 992px) {
            width: 100%;
        }

        button {
            font-family: $primary-font;
            font-weight: normal;
            line-height: 33px;
            font-size: 14px;
            color: #2C3C4F;
            position: relative;
            background: #F5F5F5 !important;
            box-shadow: none !important;
            overflow: hidden;
            border-radius: 30px;
            margin: 0;
            max-width: 170px !important;
            padding: 0 !important;

            @media screen and (max-width: 992px) {
                font-size: 14px;
                flex: 1;
            }

            &.active,
            &:hover {

                background: $primary-color !important;
                color: #fff !important;
            }

            &:after {
                content: "";
                width: 80px;
                height: 3px;
                position: absolute;
                bottom: 0;
            }

            &:hover,
            &.active {
                &:after {
                    background: $primary-color;
                }
            }
        }
    }
}

.activity {
    table {

        width: 100%;
        border: none;
        table-layout: fixed;
        empty-cells: show;
        border-spacing: 0;

        .bg-color {
            background: #EBF1F9;
            color: $primary-color;
        }


        th {
            // border: 1px solid #cfcfcf;
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            background: #EBF1F9;
            ;
            color: $primary-color;

            .border-top {
                border-top: 1px solid rgba(180, 181, 187, 0.20);
            }

            .border-right {
                border-right: 1px solid rgba(180, 181, 187, 0.20);
            }

            label {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                min-height: 36px;
            }

            span {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                min-height: 46px;
                padding: 0 2px;
            }
        }

        td {
            text-align: center;
        }

    }


    .table-heading {
        overflow-x: auto;
    }

    .table-heading>table {
        background: #ffff;
        min-width: 1300px;
        border: 1px solid rgba(180, 181, 187, 0.20);
        border-radius: 8px;
        overflow: hidden;

        >tr {
            &:nth-child(even) {
                background-color: #f2f2f2;
            }

            >th {
                border-right: 1px solid rgba(180, 181, 187, 0.20);

                &:last-child {
                    width: 460px;
                }

            }

            >td {
                border-right: 1px solid rgba(180, 181, 187, 0.20);
                font-size: 14px;

                span {
                    min-height: 46px;
                    display: flex;
                    align-items: center;
                    color: $primary-color;
                    justify-content: center;
                    width: 100%;
                    font-size: 13px;
                    text-align: left;
                    >div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding-left: 8px;
                        border-left: 10px solid transparent;
                        min-height: 46px;
                        >div{
                            min-width: 65px;
                        }

                        // &.green {
                        //     border-color: #23B478;

                        // }

                        // &.light-green {
                        //     border-color: #C1E000;

                        // }

                        // &.dark-green {
                        //     border-color: #07604B;

                        // }

                        // &.purple {
                        //     border-color: #8608B3;

                        // }

                        // &.dark-blue {
                        //     border-color: #234CB7;

                        // }

                        // &.blue {
                        //     border-color: $primary-button;
                        // }

                        // &.red {
                        //     border-color: #D52649;
                        // }

                        // &.yellow {
                        //     border-color: #EFA811;
                        // }

                        button {
                            min-width: 32px;
                            padding: 0;
                            min-height: auto;

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }

                .border-right {
                    border-right: 1px solid rgba(180, 181, 187, 0.20);
                }
            }
        }
    }
}

.account-menu {
    .MuiMenu-paper {

        background: transparent !important;
        min-width: 250px !important;
        padding-top: 23px;
        margin-left: 10px !important;
        max-width: 280px;
        position: relative;
        box-shadow: none;

        &:before {
            content: '';
            background: url("../../images/resonante/arrow-nav.png") no-repeat;
            width: 30px;
            height: 22px;
            right: 10px;
            top: 11px;
            position: absolute;
        }

        ul {
            background: $primary-color !important;
            border-radius: 8px;
            overflow: hidden;
        }

        ul li {
            border-color: rgba(173, 174, 180, 0.20) !important;

            &:hover {
                background: #122235 !important;
            }

            span,
            a {
                color: #fff !important;
                font-size: 15px;
                padding: 3px 0;
            }
        }
    }
}

.leave-management {
    .datatable {


        li .list-content .left-content {
            .heading {
                .applied-date {
                    border-radius: 0;
                }

            }

        }

    }

}

.leave-management-form {
    .datatable {
        margin-bottom: 16px;
        width: 70%;
        height: 300px;

        .cs-checkbox span:nth-child(2) {
            transform: translateY(0.2rem);
            min-width: 120px;
        }

        li .list-content {
            .left-content {
                .heading {
                    font-weight: normal !important;

                    &.bold {
                        font-weight: bold !important;
                    }

                }

                .hover-dropdown {
                    margin: 4px 0 0 0;
                }

            }

            .blue-bg {
                .no-leave {
                    width: 140px;

                    label {
                        text-align: left;
                        font-size: 14px;
                        color: #000 !important;
                        padding: 4px 6px;
                        text-align: center;
                        background: #EEEDEB;
                        border-radius: 4px;
                    }
                }
               
            }
        }

    }

    .loading-section {
        position: absolute;
        left: 0;
        top: 50%;
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.billing-table {
    table {
        width: 100%;
        font-size: 15px;

        td {
            padding: 4px;
        }

        thead th {

            text-align: left;
            padding: 10px 8px;
            background: #EBF1F9;
            color: $primary-color;
        }

        tbody {
            border-bottom: 2px solid #666;

            tr:nth-child(odd) {

                background: #ededed;
            }

            td {
                padding: 12px 8px;
                text-align: left;

                &:last-child {
                    font-weight: bold;
                }

            }



        }
    }
}

.navigation-link {
    display: flex;
    justify-content: end;
    padding: 14px 0 14px 0;

    button {
        margin-left: 18px;
    }
}

.help-box {
    overflow-y: scroll;
    height: 60vh;
    background: #fff;
    border-radius: 4px;
    padding: 10px;

    .query-list {
        margin-top: 0;

        li {
            margin-bottom: 10px;
            display: block;
            padding: 0 16px;
            border-radius: 5px;
            background: #FFF;

            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);


            .top-content {
                display: flex;
                width: 100%;
                justify-content: space-between;
                border-bottom: 1px solid #434343;
                padding: 10px 8px;

                @media screen and (max-width: 767px) {
                    display: block;
                }

                .hover-effect {}

                .left-line {
                    border-left: 1px dotted #434343;
                    padding-left: 10px;

                    @media screen and (max-width: 767px) {
                        border-left: none;
                        padding-left: 0;
                    }
                }

                label {
                    display: flex;
                    align-items: center;
                    position: relative;
                    font-size: 15px;
                    min-width: 30%;
                    justify-content: flex-start;

                    .cs-dropdown {
                        width: 60px;
                        padding: 0;
                        margin: 0 10px 0 0;

                        .form-select {
                            min-width: 20px;
                            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
                            border-radius: 3px;
                            background: #FFF;

                            padding: 0 0 0 6px;

                            &::after {
                                // transform: rotate(90deg);
                                margin-top: 3px;
                                margin-right: 8px;
                            }

                            >div {
                                padding: 0 !important;
                            }
                        }

                        fieldset {
                            border: none;
                        }

                        // hover-effect add tag class help and support screen
                        .tag {
                            display: none;
                            position: absolute;
                            bottom: -31px;
                            left: 0;
                            border-radius: 5px;
                            min-width: 90px;
                            text-align: center;
                            color: #fff;
                            background: #999;
                            font-size: 12px;
                            padding: 7px 6px;

                            &.red {
                                background: #ed5c5c;


                            }

                            &.orange {
                                background: #ee9849;


                            }

                            &.green {
                                background: #1c9690;

                            }
                        }

                        &:hover {
                            .tag {
                                display: block;
                            }
                        }

                        &.red {
                            background: #ed5c5c;

                            &:hover {
                                .tag {
                                    background: #ed5c5c;
                                }
                            }
                        }

                        &.orange {
                            background: #ee9849;

                            &:hover {
                                .tag {
                                    background: #ee9849;
                                }
                            }
                        }

                        &.green {
                            background: #1c9690;

                            &:hover {
                                .tag {
                                    background: #1c9690;
                                }
                            }
                        }
                    }
                }

                .flag-option {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left: 6px;
                }

                span {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 50%;
                    text-align: center;
                    font-style: normal;
                    line-height: 1;
                    position: relative;
                    cursor: pointer;

                    .tag {
                        display: none;
                        position: absolute;
                        bottom: -31px;
                        left: 0;
                        border-radius: 5px;
                        min-width: 90px;
                        text-align: center;
                        color: #fff;
                        background: #999;
                        font-size: 12px;
                        padding: 7px 6px;
                    }

                    &:hover {
                        .tag {
                            display: block;
                        }
                    }

                    &.red {
                        background: #ed5c5c;

                        &:hover {
                            .tag {
                                background: #ed5c5c;
                            }
                        }
                    }

                    &.orange {
                        background: #ee9849;

                        &:hover {
                            .tag {
                                background: #ee9849;
                            }
                        }
                    }

                    &.green {
                        background: #1c9690;

                        &:hover {
                            .tag {
                                background: #1c9690;
                            }
                        }
                    }
                }

                .dropdown-arrow {
                    button {
                        min-width: auto;

                        svg {
                            transform: rotate(270deg);
                            transition: all 0.3s ease-in-out;
                        }

                        &[aria-expanded="true"] {
                            svg {
                                transform: rotate(360deg);
                            }
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            .content {
                display: block;
                width: 100%;
                padding: 10px 8px;
                font-size: 15px;

                .link {
                    min-width: 190px;
                    display: flex;
                    align-items: center;
                }
            }

            .bottom-content {
                display: flex;
                width: 100%;
                padding: 0 8px 10px 8px;
                align-items: start;
                font-size: 15px;

                label {
                    min-width: 64px;
                    font-weight: bold;
                }
            }
        }
    }
}


.grid-datatable {
    margin-top: 20px;

    .grid {
        background: transparent;

        label {
            display: flex;
            padding: 14px 10px;
            justify-content: space-between;

            img {
                width: 26px;
            }

            button {
                background: transparent;
                min-height: auto;
            }
        }
    }

    .grid-heading {
        border-radius: 5px 5px 0px 0px;
        background: #EBF1F9;
        font-size: 18px;

    }

    .grid-data {
        border-radius: 0px 0px 5px 5px;
        background: #FFF;

        label {
            font-size: 16px;
        }

        &:nth-child(odd) {
            background-color: #f7f7f7;
        }
    }
}

.scroll-view {
    overflow-x: auto;
    margin-top: 10px;

    .content-view {
        min-width: 1280px;
    }
}

.venue-heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    position: relative;

    .heading-group {
        display: flex;
        align-items: flex-start;
        width: 100%;

        .input-group {
            flex: 1 1;

            &:first-child {
                width: 100px;
                flex: none;
            }
        }
    }

    label {
        font-weight: bold;
        font-size: 15px;
        color: $primary-color;
    }

    .label-name {
        border-right: 1px solid #D9D9D9;
        min-width: 150px;
        margin-right: 30px;
        min-height: 46px;
        display: flex;
        align-items: center;
        padding: 0 0 7px 0;

        &.afternoon-label {
            border-left: 1px solid #D9D9D9;
            border-right: none;
            min-width: 20px;
            margin-right: 10px;
            margin-left: 0;
        }
    }
}

.venue-calc {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    position: relative;

    .label-name {
        border-right: 1px solid #D9D9D9;
        min-width: 150px;
        margin-right: 30px;
        min-height: 60px;

        &.afternoon-label {
            border-left: 1px solid #D9D9D9;
            border-right: none;
            min-width: 20px;
            margin-right: 10px;
            margin-left: 0;
        }
    }

    .checkbox-group {
        width: 100px;
    }

    label.bold {
        font-weight: bold;
    }

    .link-copy {
        width: 50px;
    }

    .copy-box {
        position: relative;
        padding-right: 20px;
        min-width: 60px;

        .hover-dropdown.left .content-area {
            right: 2px;

            span {
                right: 2px;
            }
        }

        >div {
            position: absolute;
            bottom: -8px;
            right: 20px;
        }

        button.link-btn {
            width: 46px;
            height: 46px;
            display: flex;
            background: #fff;
            border-radius: 50%;
            min-width: auto;
            align-items: center;
            justify-content: center;

            &:focus,
            &:active,
            &:hover {
                background: #fff;
            }
        }
    }

    &.disabled {
        opacity: 0.3;
    }

    .input-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0 40px 0 0;
        text-align: left;
        justify-content: left;
        flex: 1 1;
        // &.flex-end {
        //     justify-content: flex-end;
        // }

        .input-align {
            margin-right: 12px;
            width: 100%;
            display: flex;
            position: relative;

            .MuiSelect-select {
                padding-left: 0 !important;
                position: relative;
                z-index: 9;
            }

            @media screen and (max-width: 767px) {
                min-width: 110px;
            }

            @media only screen and (max-width:1000px) and (min-width: 767px) {
                min-width: 100px;
            }

            .label-val {
                position: absolute;
                left: -7px;
                top: 8px;
                flex: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 58px;
                align-items: center;
                font-size: 14px;
                text-align: right;
                display: none;
            }

            >div {
                height: 42px;
                margin-left: 0;
                position: relative;
                margin-bottom: 0;

                &:after {
                    content: "";
                    background: url("../../images/icons/down-arrow-light.png") no-repeat;
                    width: 18px;
                    height: 9px;
                    content: "";
                    margin-right: 0;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 8px;
                    top: 16px;
                    z-index: 1;
                }
            }
        }
    }

    label,
    >div {
        // flex: 1;
        font-size: 15px;
        color: $primary-color;
    }

    .form-input.icon {
        &:after {
            content: "";
            background: url("../../images/icons/down-arrow.png") no-repeat;
            width: 18px;
            height: 9px;
            content: "";
            margin-right: 6px;
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

}

.map-modal {
    .modal-content {
        height: 96vh;
        width: 98% !important;
    }

    .google-map {
        border-radius: 18px;
        height: 76vh;
        overflow: hidden;
        padding-top: 60px;
        width: 100%;
    }

    .places-container {
        padding: 0 30px;
        position: relative;

        .search-result {
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
            z-index: 999;
            background: #fff;

            div {

                padding-left: 10px;
                padding-top: 6px;
                font-size: 17px;
            }

            input {
                border: 1px solid #cfcfcf;
                border-radius: 8px;
                box-shadow: none;
                font-size: 18px;
                min-height: 48px;
                outline: none;
                padding-left: 10px;
                width: 100%;
            }
        }
    }
}

.main-container {
    >div {
        @media screen and (max-width: 1100px) {
            display: none;
        }

    }
    .footer-main{
        position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    right: 0;
    }

    .mobile-message {
        display: flex;
        width: 100%;
        min-height: 70vh;
        text-align: center;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 1100px) {
            display: none;
        }

        h2 {
            font-size: 40px;
            color: #4d4d4d;
            font-weight: bold;
            text-transform: capitalize;
            padding-bottom: 10px;
        }

        p {
            font-size: 20px;
            color: #575757;
        }


    }
}

.scheduler .rbc-calendar {
    .rbc-time-view {
        background: #fff;
    }

    .rbc-header {
        min-height: 100px !important;
        padding: 8px !important;
        background: #EAF1F9;
    }

    // .rbc-label {
    //     display: none;
    // }
    .rbc-header,
    .rbc-label {
        color: #000000;
    }

    .rbc-time-gutter,
    .rbc-time-header-gutter {
        min-width: 120px !important;
        max-width: 120px !important;
    }

    .rbc-time-slot {
        font-size: 14px;
        text-align: left;
    }

    .rbc-day-slot .rbc-event,
    .rbc-day-slot .rbc-background-event {
        border: 1px solid #FFF;
    }

    .rbc-toolbar button {
        color: $primary-color;
        background: #fff;
        border-color: #d9d9d9;
        cursor: pointer;

        &.rbc-active {
            box-shadow: none;
            color: $white;
            border-color: $primary-color;
            background: $primary-color;
        }
    }

    .rbc-toolbar-label {
        font-weight: bold;
        color: $primary-color;
        font-size: 18px;
    }

    .sch-main {
        display: flex;
        align-items: start;
        position: relative;
        
        img {
            border-radius: 50%;
        }

        .sch-heading {

            .title {
                color: $primary-color;
            }

            .designation {
                text-align: left;
                color: $primary-color;
                font-weight: normal;
                font-size: 16px;
            }

            .activity {
                margin-top: 4px;

                button {
                    border: none;
                    border-radius: 33px;
                    background: $primary-color;
                    padding: 4px 12px 6px 12px;
                    cursor: pointer;
                    color: #fff;
                }

            }
        }
    }

    .rbc-event {
        border-radius: 1px !important;
        border: 1px solid #FFF;
        border-left: 8px solid #D52649 !important;
        color: #D52649 !important;
        font-size: 13px !important;
        background: #FFEDF0 !important;
    }

    .rbc-time-column .rbc-timeslot-group {
        // border: 1px solid red;
        min-height: 90px;
    }

    .rbc-row-content {
        min-height: 90px;
    }
}