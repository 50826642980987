.right-bar {
    position: fixed;
    right: -340px;
    width: 328px;
    top: 70px;
    height: 100vh;
    background: #F5F5F5;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 10;
    transition: all 0.3s ease-in;
    // display: none;
    .top-section {
        padding: 20px 20px 8px 20px;
        border-bottom: 1px solid rgba(173, 174, 180, 0.20);
        align-items: center;
        h6 {
            color: #282b3b;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
        }
        .clear-btn {
            font-family:$primary-font;
            margin-right: 20px;
            font-size: 14px;
            &:hover {
                text-decoration: underline;
            }
        }
        button {
            background: transparent;
            padding: 0;
            min-width: auto;
            &:hover {
                background: transparent;
            }
        }
    }
    .sidebar-accordion {
        
        > div {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid rgba(173, 174, 180, 0.20);
            box-shadow: none;
            background: #F5F5F5;
            &:before{
                display: none;
            }
        }
        .Mui-expanded{
            margin: 0 !important;
        }
        .accordion-panel {
            position: relative;
            background: #F5F5F5;
            border-radius: 10px;
            min-height: auto;
            padding: 16px 18px;
            > div {
                align-items: center;
                margin: 0;
            }
            .heading {
                color: $primary-color;
                font-family:$primary-font;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 15px;
            }
            &:after {
                content: "";
                background: url(../../images/icons/expand.png) no-repeat;
                width: 7px;
                height: 18px;
                background-size: 100%;
                position: absolute;
                right: 21px;
                top: 17px;
                transition: all 0.3s ease-in;
            }
            &.Mui-expanded:after {
                transform: rotate(90deg);
                right: 23px;
                top: 16px;
            }
        }
        .accordion-detail {
            display: block;
            width: 100%;
            padding-top: 0;
            >div:first-child{
                margin-top: 0;
            }
            .checkbox-section {
                margin-top: 10px;
                label {
                    display: flex;
                    font-family:$primary-font;
                    span {
                        color: $primary-color;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: normal;
                        font-family:$primary-font;
                        line-height: 8px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
    &.open{
        right: 0;
    }
}