$primary-color: #16273C;
$dark-bg:#1F1F24;
$secondary-color: #ec7e1d;
$color-orange: #FF7557;
$color-yellow: #F8BC3B;
$color-lotus:#7856FF;
$white:#fff;
$black:#000;
$text-grey:#1D1E21;
$text-light-grey:#626266;
$text-footer-link:#8F8F91;
$primary-button:#16273C;




// Font family
$primary-font:'Gotham-Book';
$primary-font-bold:'Gotham-Bold';
  
  // $apercu-light: 'Apercu-Light';
  // $apercu-bold: 'Apercu-Bold';
  //  $apercu-pro: 'ApercuPro-Medium';

