.report-page {
    .report-section {
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 20px;

        >div {
            align-items: center;
        }

        .forminput {
            min-width: 280px;
            // border-radius: 50px !important;

            // input {
            //     border-radius: 50px !important;

            // }

            // fieldset {
            //     border-color: transparent;
            // }
        }

        .react-calendar{
            position: absolute;
    z-index: 99;
    border-radius: 10px;

        }
        .input-icon>button {
            top: 25px;
            right: 17px;
            bottom:auto;
        }

        .button {
            border-radius: 42px;
            background: #FFF;
            text-align: center;
            min-width: 120px;
            margin-right: 16px;
            color: $primary-button;
            padding: 8px;
            justify-content: space-between;
        }

        .tab-main {
            min-width: 440px;

            .MuiTabs-fixed {
                width: 100%;
            }
        }
    }

    .report-heading {
        .primary-btn.btn {
            padding: 4px 40px;
        }

        .cs-dropdown {
            .form-select {
                position: relative;
                padding: 0;
                border-radius: 20px;
                min-width: 380px;
            }
        }
    }

    .report-table {

        border-radius: 5px 5px 0px 0px;
        overflow: hidden;



        .group-main {
            display: flex;
            background: #606B7A;

            .activity-grid {
                overflow-x: auto;
                background-color: #fff;
                width: 100%;
                display: flex;

                .grid-box {
                    display: table-row;
                }



                .top-month-bar {
                    background: #606B7A;
                    text-align: center;
                    width: 100%;
                    padding: 4px 0;
                    height: 44px;

                    h5 {
                        font-size: 14px;
                        text-transform: uppercase;
                        padding: 6px 4px;
                        letter-spacing: 1.43px;
                        font-weight: bold;
                        color: #fff;
                        border-right: 1px solid #fff;
                    }
                }

                .calender-list {
                    width: 100%;
                    height: 44px;
                    background: #EAF1F9;
                    display: flex;
                    align-items: center;

                    ul {
                        display: flex;
                        background: #EAF1F9;
                        width: 100%;

                        li {
                            list-style: none;
                            min-width: 100px;
                            color: #16273C;
                            border-right: 1px solid #e2e7ee;
                            position: relative;
                            font-size: 12px;
                            font-weight: bold;
                            padding: 0 2px;
                            justify-content: center;
                            display: flex;
                            height: 44px;
                            align-items: center;
                            text-align: center;
                            line-height: 13px;
                        }
                    }
                }

                .mid-bar {
                    background: #CDD6E1;
                    text-align: center;
                    height: 38px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    padding-left: 30%;
                    border-bottom: 1px solid #fff;

                    h6 {
                        font-size: 15px;
                        font-weight: normal;
                        padding: 0px 4px;
                        // border-bottom: 2px solid #fff;
                    }
                }

                .event-main {
                    width: 100%;
                    background: #fff;
                    height: 38px;

                    &:nth-child(even) {
                        background: #f7f7f7;
                        /* Light grey background for even items */
                    }

                    ul {
                        display: flex;
                        width: 100%;

                        li {
                            list-style: none;
                            min-width: 100px;
                            // border-bottom: 1px solid #EBEBEC;
                            border-right: 1px solid #f0f0f1;
                            position: relative;
                            height: 38px;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            padding: 0 4px;
                            justify-content: center;
                        }
                    }
                }
            }

            .user-group {
                -webkit-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.42);
                -moz-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.42);
                box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.42);
                position: relative;
                background: #f1f1f1;
            }

            .user-data {
                margin-top: 0;
                padding: 44px 0 0 0;
                background: #616a7a;

                .blank-heading {
                    background: #CDD6E1;
                    height: 37px;
                }

                .blank {
                    background: #EBF1F9;
                    height: 45px;
                }

                ul {
                    width: 180px;
                    background: #EAF1F9;

                    li {
                        list-style: none;
                        // border-top: 1px solid #fff;
                        background: #fff;
                        font-size: 14px;
                        height: 38px;
                        padding: 0 10px;
                        display: flex;
                        align-items: center;

                        &:nth-child(even) {
                            background: #f7f7f7;
                            /* Light grey background for even items */
                        }
                    }
                }
            }

        }

    }
}




.help-page {
    .right {
        display: flex;
        justify-content: end;

        .right-chat {
            display: flex;
            align-items: end;
            justify-content: end;
            margin-bottom: 16px;

            .content {
                border-radius: 5px 5px 0px 5px;
                background: $primary-color;
                padding: 12px 20px;
                max-width: 70%;
                display: inline-table;

                .list {
                    display: flex;
                    color: #fff;
                    font-size: 16px;

                    p {
                        color: #fff;
                        font-size: 16px;
                        word-break: break-all;
                    }

                    label {
                        margin-right: 8px;
                        font-weight: bold;
                        color: #FFF;
                        font-size: 16px;
                        min-width: 80px;
                    }
                }

                .date {
                    p {
                        color: #fff;
                    }

                    text-align: right;
                    font-size: 14px;
                    margin-top: 8px;
                }

                .username {
                    text-align: right;
                    font-size: 14px;
                    color: #c1c1c1;
                    margin-top: 2px;
                    width: 180px;
                    @include truncate;
                }
            }

            .user {
                margin-left: 16px;
            }
        }
    }

    .chat-section {
        height: 56vh;
        overflow-y: scroll;
    }

    .left {
        .left-chat {
            display: flex;
            align-items: end;
            justify-content: flex-start;
            margin-bottom: 16px;

            .content {
                border-radius: 5px 5px 5px 0px;
                background: #fff;
                padding: 12px 20px;
                max-width: 70%;
                display: inline-table;

                .list {
                    display: flex;
                    font-size: 16px;

                    label {
                        margin-right: 8px;
                        font-weight: bold;
                        font-size: 16px;
                    }
                }

                .date {
                    text-align: left;
                    font-size: 14px;
                    margin-top: 8px;

                    p {
                        color: #6f7074;
                    }
                }

                .username {
                    text-align: left;
                    font-size: 14px;
                    color: #6f7074;
                    margin-top: 2px;
                    width: 180px;
                    @include truncate;
                }
            }

            .user {
                margin-right: 16px;
                width: 40px;
                height: 40px;
                background: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .chat-message {
        position: relative;
        padding: 10px 0 0 0;

        textarea {
            border-radius: 20px;
            padding: 16px;
        }

        .link-btn {
            position: absolute;
            right: 10px;
            top: 16px;
        }
    }

}

.privacy-page {
    padding: 30px 30px;
    background: #fff;
    border-radius: 16px;

    .heading {
        margin-top: 10px;
        text-align: center;
        margin-bottom: 30px;
        font-size: 20px;
    }

    h5 {
        text-align: center;
        display: block;
        font-size: 20px;
    }

    .content {
        p {
            font-size: 16px;
        }

        >ul {
            padding-left: 16px;
            padding: 0;

            >li {
                list-style: auto;
                margin-top: 22px;

                margin-left: 22px;
                font-weight: bold;
                font-size: 16px;
            }

            p {
                font-size: 16px;
            }
        }

        ol {
            padding-left: 16px;
            padding: 0;

            li {
                list-style: disc;

                font-size: 16px;
                margin-left: 22px;
            }
        }
    }
}