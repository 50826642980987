button {
  font-family: $primary-font !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none !important;
}

.primary-btn.btn {
  background: $primary-button;
  padding: 4px 20px;
  min-width: 150px;
  font-family: $primary-font;
  font-size: 15px;
  border-radius: 60px;
  font-weight: normal;
  color: #fff;
  display: flex;
  justify-content: center;
  text-transform: none !important;

  &:focus,
  &:active,
  &:hover {
    background: $primary-button !important;
    color: #fff;
  }

  &.xs {
    font-size: 14px;
    padding: 2px 20px;
    min-height: 2.1rem;
  }

  &.xl {
    min-width: 220px;
    font-size: 16px;
    padding: 12px 20px;
  }
  &:disabled{
    background: $primary-button !important;
    color: #fff !important;
  }
}

button.light-btn,
button.btn-second {
  box-shadow: 0px 0px 24px 0px rgba(40, 43, 59, 0.1);
  border-radius: 60px;
  color: $primary-button;
  padding: 11px 20px;
  min-width: 150px;
  background: #fff;
  font-family: $primary-font;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  img {
    width: auto;
  }

  &:hover {
    color: #fff;
    background: $primary-button !important;
    box-shadow: 0px 0px 24px 0px rgba(40, 43, 59, 0.1);
  }

  &:focus {
    box-shadow: 0px 0px 24px 0px rgba(40, 43, 59, 0.1) !important;
  }

  &.white {
    &:hover {
      color: $primary-button;
      background: #fdfdfd !important;
      box-shadow: 0px 0px 24px 0px rgba(40, 43, 59, 0.1);
    }
  }

  &.radius-none {
    border-radius: 0;
  }
  &:disabled{
    background: $primary-button !important;
    color: #fff !important;
  }

}
.link-btn.btn{
  border-radius: 3px;
  border: 1px solid $primary-button;
  color: $primary-button;
  min-height: auto;
  padding: 5px 10px;
  min-width: 120px;
  margin-left: 16px;
  background: transparent;
}

button.dark-btn,
button.secondary-btn {
  background: $primary-button !important;
  padding: 11px 20px;
  min-width: 240px;
  font-family: $primary-font;
  border-radius: 60px;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
&.transparent{
  color: $primary-button !important;
  background: transparent !important;
  border:1px solid  $primary-button !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  &:hover {
    color: $primary-button !important;
    background: #FFF !important;
    border:1px solid  $primary-button !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }
  &:disabled{
    background: transparent !important;
    color: $primary-button !important;
  }
}
  &.white {
    color: $primary-button !important;
    background: #FFF !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);

    &:hover {
      color: $primary-button !important;
      background: #FFF !important;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    }
    &:disabled{
      background: #fff !important;
      color: $primary-button !important;
    }
  }

  img {
    width: auto;
  }

&:disabled{
  background: $primary-button !important;
  color: #fff !important;
}
}

button.secondary-btn2 {
  background: $primary-color !important;
  padding: 14px 10px;
  font-family: $primary-font !important;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  min-width: 210px;
  font-weight: 500;
  box-shadow: 0rem 0rem 0rem 0rem rgba(52, 71, 103, 0.15), 0rem 0rem 0rem -0.125rem rgba(52, 71, 103, 0.2), 0rem 0rem 0rem 0rem rgb !important;

  &:hover {
    box-shadow: 0rem 0rem 0rem 0rem rgba(52, 71, 103, 0.15), 0rem 0rem 0rem -0.125rem rgba(52, 71, 103, 0.2), 0rem 0rem 0rem 0rem rgb !important;
  }

  &.light {
    background: #FFF !important;
    color: $primary-color !important;
  }
}

button.blue-btn {
  box-shadow: none;
  border-radius: 40px;
  color: #fff;
  padding: 7px 22px 9px 22px;
  font-size: 16px;
  min-width: 160px;
  background: $primary-button;
  font-family: $primary-font;

  img {
    width: auto;
  }

  &:hover {
    color: #fff;
    background: $primary-button !important;
    box-shadow: none;
  }

  &:focus {
    box-shadow: none !important;
  }
}



.icon-align {
  position: relative;

  .icon {
    position: absolute;
    right: 10px;
    bottom: 1px;

    &.bottom-6 {
      bottom: 6px;
    }

    &.bottom-8 {
      bottom: 8px;
    }
  }

}

button.icon-toggle {
  .white {
    display: none;
  }

  background: #fff;

  &:hover {
    .white {
      display: block;
    }

    .black {
      display: none;
    }
  }
}