.brand-details {
    .upload-logo {
        .upload-btn {
            width:141px;
            height: 141px;
            padding: 0 !important;
            border-radius: 10px;
            display: block;
            span {
                justify-content: center;
                width: 100%;
                font-weight: normal;
                color: #a5a6ad;
                text-transform: capitalize;
                display: flex;
            }
        }
    }
    .survey-dropdown {
        width: 100%;
        > div {
            margin-top: 2px;
            margin-bottom: 8px;
            @media screen and (max-width: 767px) {
                margin-bottom: 8px;
            }
        }
    }

}

canvas{
    width:100% !important;
    height: auto !important;


}