
// custom checkbox button
.cs-checkbox {
    display: flex !important;

    span:nth-child(2) {
        padding: 0px 0 0 3px !important;
        margin-bottom: 0;
        font-weight: normal;
        color: $primary-color;
    }
    span{
        min-height: 17px;
        
    }

    .Mui-checked>span {
        &:before {
            visibility: hidden;
        }

        &:after {
            content: "";
            overflow: inherit !important;
            background: url("../../images/icons/checkbox-fill.png") no-repeat;
            width: 20px;
            height: 20px;
            background-size: 100%;
            position: absolute;
            left: 0;
            top: 1px;
            z-index: 9;
            border-radius: 1px;
        }
    }

    span {
        float: left;
        border-radius: 0;
        

        svg {
            visibility: hidden;
        }
    }

    input:before {
        display: none;
    }

    >span:nth-child(1) {
        flex:none;
        position: relative;
        padding-left: 0;
        margin: 0 0 0 12px;
        width: 20px;
        height: 21px;

        :before {
            content: "";
            overflow: inherit;
            background: url("../../images/icons/checkbox.png") no-repeat;
            width: 20px;
            height: 20px;
            background-size: 100%;
            position: absolute;
            left: 0;
            top: 1px;
            z-index: 1;
        }
    }
}

.scrollbar {
    overflow-y: auto;
    height: 70vh;
    padding: 20px 30px 20px 20px;
    background: #fff;
    border-radius: 8px;
}

.Mui-disabled {
    opacity: 60%;
}

.pagination-section {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
    margin-top: 16px;

    .result-text {
        font-size: 18px;
        font-family: $primary-font;
        margin-right: 10px;
    }

    .dropdown-select {
        min-width: 110px;

        >div>div {
            padding: 0 8px;
        }

        div {
            font-size: 18px;
            font-family: $primary-font;
        }
    }

    .MuiPagination-ul li button {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #cfcfcf;
        font-size: 18px;
        font-family: $primary-font;
        margin-left: 8px;

        &.Mui-selected {
            background: #ecebeb;
            cursor: default;
        }
    }
}

.MuiSwitch-sizeMedium {
    width: 64px !important;
    padding: 12px 0 !important;

    .MuiSwitch-switchBase {
        transform: translateX(20px);

        &.Mui-checked {
            transform: translateX(40px);
        }
    }

    .MuiSwitch-track {
        width: 2.5rem;
    }
}

.alert {
    position: relative;
    padding: 12px 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 15px;
    text-align: left;
    margin-top: 16px;
    border-radius: 6px;
    text-align: center;

    &.alert-success {
        color: #007872;


        background-color: #EAF5F5;
        border-color: #EAF5F5;
    }

    &.alert-danger {
        color: #C53434;
        background-color: #F9EBEB;
        border-color: #F9EBEB;
    }
}

@media screen and (max-width: 767px) {
    .filter-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.2);
        z-index: 9;

        >div {
            margin: 0;
        }

        >div>div {
            display: flex;
            width: 100%;

            button,
            >div {
                flex: 1;
                margin-right: 0;
                border-radius: 0;
                box-shadow: none;
                min-width: auto;

                button {
                    width: 100%;
                    margin-left: 0;
                    border-radius: 0;
                    border-right: 1px solid #f3f3f3;
                }
            }

            >div.date {
                border-left: 1px solid #f3f3f3;
            }

            button {
                padding: 18px 10px !important;
            }
        }
    }
}

// Custom Radio Button
.cs-radio {
    display: flex !important;

    span {
        float: left;
        line-height: normal !important;
        z-index: 0;
        flex: none;
    }

    span:nth-child(1) {
        position: relative;
        padding-left: 0;
        margin: -4px 0 0 12px;
        width: 20px;
        height: 20px;

        span:nth-child(1) {
            /* overflow: hidden; */
            border-radius: 0;
            visibility: hidden;
        }

        span~span {
            display: none;
        }

        span {
            svg {
                visibility: hidden;
            }
        }

        span:before {
            content: "";
            background: url("../../images/icons/radio-bg.png") no-repeat;
            width: 20px;
            height: 20px;
            background-size: 100%;
            position: absolute;
            left: -4px;
            top: 4px;
            z-index: 1;
        }
    }

    >span:after {
        z-index: 9 !important;
        left: -10px !important;
        top: 8px !important;
        width: 20px !important;
        height: 20px !important;
        background-image: linear-gradient(195deg, #16273C, #16273C);
    }

    .Mui-checked>span {
        &:before {
            visibility: hidden;
        }
    }

    &.Mui-disabled {
        opacity: 0.5;
    }
}
.cs-new-dropdown{
>div{
    min-height: 44px;
    >div{
        padding: 0 8px;
    }
}
div{
    font-size: 14px !important;
    font-family: $primary-font  !important;
    color: $primary-color;
}
span{
    font-size: 14px !important;
    font-family: $primary-font  !important;
    color: $primary-color;
}
font{
  
    
}
}
#react-select-2-listbox{
    font{
        font-size: 14px !important;
        font-family: $primary-font  !important;
    }
    
}
.cs-dropdown {
    width: 100%;

    .form-select {
        position: relative;
        padding: 0;

        span {
            // min-height: 30px;
        }
    }

    .css-1jqq78o-placeholder,
    .css-1dimb5e-singleValue,
    .css-1nmdiq5-menu,
    .css-1nmdiq5-menu div {
        font-size: 18px;
        font-family: $primary-font;
        text-transform: capitalize;
    }

    .css-qbdosj-Input {
        font-size: 16px;
        min-height: 38px;
        font-family: $primary-font;
    }

    &.icon {
        .form-select:after {
            content: "";
            background: url("../../images/resonante/nav-arrow.png") no-repeat;
            width: 18px;
            height: 15px;
            content: "";
            margin-right: 6px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &.fieldset fieldset {
        top: 0;
    }

    .MuiInputBase-fullWidth {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}

.MuiPickersLayout-root .MuiDialogActions-spacing {
    padding: 0;

}

.MuiMultiSectionDigitalClock-root li {
    justify-content: flex-start !important;
    padding: 6px;

    &.Mui-selected {
        background-color: #18263c !important;

        &:focus,
        &:hover {
            background-color: #18263c !important;
            color: #fff;
        }
    }
}

.MuiPaper-elevation.MuiMenu-paper {
    padding: 0;

    .MuiMenu-list {
        display: grid;
        min-width: 17rem;

        li {
            justify-content: flex-start;
            padding: 8px 0 8px 10px;
            border-bottom: 1px solid #e9e9eb;
            font-family: $primary-font !important;
            overflow: hidden;
            text-transform: capitalize;
            margin-bottom: 0;
            border-radius: 0;
            color: $primary-color;
            display: flex;

            a {

                min-width: 230px;


                >span {
                    min-width: 230px;
                    margin: 0;
                }
            }

            .color-box {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin: 0 6px 0 0;
            }

            a p span {
                width: 0;
                height: 0;
            }

            a p img {
                padding-right: 8px;
            }
        }

        li.menu-item {
            letter-spacing: 0;
        }
    }
}

.language-menu {
    li a p {
        width: 37px;
    }
}

.base-Popper-root.MuiAutocomplete-popper {

    // border: 1px solid red !important;
    >div {
        box-shadow: none !important;
        border: none !important;
    }

    .list-drop {
        // border: 1px solid green !important;
        font-size: 16px;
        padding-left: 6px;
        font-family: $primary-font;
        margin-bottom: 4px;

        >span {
            margin-right: 6px;
        }

        .Mui-checked {
            position: relative;

            &::after {
                content: '';
                background: url('../../images/icons/checkbox-fill.png') no-repeat;
                width: 18px;
                height: 18px;
                background-size: 100% !important;
                position: absolute;
                left: 1px;
                border-radius: 2px;
                top: 1px;
                z-index: 9;
            }

            .MuiSvgIcon-root {

                // background: #1A73E8;
            }
        }

    }
}

.hover-dropdown {
    position: relative;

    svg {
        margin: 7px 0 0 0;
        display: flex;
        width: 13px;
    }

    .explanation-icon {
        width: 17px;
        height: 17px;
        margin: -4px 0 0 -2px;
        cursor: pointer;
    }

    .content-area {
        position: absolute;
        top: 20px;
        left: -30px;
        display: none;
        z-index: 9999;
        padding-top: 22px;

        @media screen and (max-width: 767px) {
            left: 80px;
        }

        @media only screen and (max-width: 1180px) and (min-width: 768px) {
            left: -245px;
        }

        span {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #2d3a4e;

        }

        >div {
            background: #2d3a4e;
            width: 360px;
            min-height: 140px;
            border-radius: 8px;
            text-wrap: initial;
            line-height: 20px;
            color: #fff;
            padding: 12px 10px 10px 10px;
            font-size: 13px;

            p {
                margin-bottom: 8px;
                color: #fff;
            }

            @media screen and (max-width: 767px) {
                width: 160px;
                padding: 10px 8px 10px 8px;
            }
        }
    }

    .explanation-icon:hover~.content-area {
        display: block;
    }

    &.left {
        .content-area {
            right: -30px;
            left: auto;

            span {
                margin: 0;
                display: flex;
                position: absolute;
                right: 22px;
                top: 10px;
            }
        }
    }

    &.right {
        .content-area {
            right: auto;
            left: -30px;

            span {
                margin: 0;
                display: flex;
                position: absolute;
                left: 22px;
                top: 10px;
            }
        }
    }

    &.quest {
        margin-left: -8px;
        margin-right: 20px;

        .content-area {
            left: -130px;
            top: 30px;

            >div {
                width: 430px;
                font-size: 12px;
                line-height: 16px;

                @media screen and (max-width: 767px) {
                    width: 300px;
                }

                p {
                    margin-bottom: 1px;
                }
            }
        }
    }

    &.quest1 {
        margin-left: -8px;

        .content-area {
            left: -270px;
            top: 30px;

            @media screen and (max-width: 767px) {
                left: -230px;
            }

            >div {
                width: 430px;
                font-size: 12px;
                line-height: 16px;

                @media screen and (max-width: 767px) {
                    width: 300px;
                }

                p {
                    margin-bottom: 1px;
                }
            }
        }
    }

    &.quest2 {
        margin-left: 10px;
        padding-top: 3px;

        .explanation-icon {
            width: 15px;
        }

        .content-area {
            left: -190px;
            top: 30px;

            // @media screen and (max-width: 767px) {
            //     left: -240px;
            // }
            >div {
                width: 360px;
                // font-size: 12px;
                // line-height: 16px;
                text-align: left;

                @media screen and (max-width: 767px) {
                    width: 280px;
                }

                p {
                    margin-bottom: 2px;
                    text-align: left;
                }
            }
        }
    }
    &.three-column{
        .content-area{
           
            img{
                width: 100%;
            }
             > div{
                width: 560px;
            }
            .image-content{
                display: flex;
                .card{
                   padding: 0 4px;
                   width: 33.6%;
                }
            }
        }
    }
}

.picker-group {
    position: relative;

    input {
        padding-left: 36px;
    }

    .color-show {
        position: absolute;
        bottom: 15px;
        border-radius: 2px;
        width: 18px;
        height: 18px;
        left: 10px;
        z-index: 1;

    }

    .color-picker {
        position: absolute;
        bottom: 2px;
        right: 10px;
        z-index: 1;
        background: transparent !important;
        min-width: auto;

        &:hover {
            background: transparent !important;
        }
    }
}

.datepicker .MuiInputBase-colorPrimary,
.time-picker .picker-main {
    border-radius: 4px;
    background: #fff;
    width: 100%;
}

.datepicker svg path {
    fill: $primary-color;
}


.cs-modal {
    .modal-dialog {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100%;

        @media screen and (max-width: 767px) {
            padding: 0 10px;
        }

        .modal-content {
            width: 800px;
            background: #F5F5F5;
            position: relative;
            border-radius: 5px;

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .modal-header {
                position: absolute;
                right: 0;
                top: 0;

                .modal-close {
                    padding: 10px 0 0 0;
                    background: transparent;
                }
            }

            .modal-heading {
                text-align: center;
                padding: 10px 15px 10px 15px;
                background: #16273B;

                h2 {
                    color: #fff;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    line-height: 33px;
                }
            }

            .modal-body {
                padding: 20px;
                .scroll-bar{
                    overflow-y: auto;
                    max-height: 200px;
                }

                p {
                    font-size: 18px;
                }
            }

            .modal-footer {
                padding: 0 20px 30px 20px;
                display: flex;
                justify-content: end;

                &.space-around {
                    justify-content: space-around;
                }

                button {
                    margin-left: 18px;
                }
            }

        }
    }

    &.picker-modal {
        .modal-dialog .modal-content {

            width: 400px;

            .modal-body {
                padding: 20px 26px 10px 26px !important;
            }

            .modal-footer {
                padding: 0 26px 28px 26px;

            }

            .picker-group {
                position: relative;
                margin-top: 20px;

                input {
                    padding-left: 36px;
                }

                .color-show {
                    position: absolute;
                    bottom: 15px;
                    border-radius: 2px;
                    width: 18px;
                    height: 18px;
                    left: 10px;
                    z-index: 1;

                }
            }

            .color-box {
                min-height: 200px;
                // border: 1px solid #e3e3e3;
                border-radius: 10px;
                // background-color: #fafafa;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &.xs {
        .modal-dialog {

            .modal-content {
                width: 600px;
                padding-top: 0;
            }
        }
    }
    &.sm {
        .modal-dialog {

            .modal-content {
                width: 690px;
                padding-top: 0;
            }
        }
    }

    &.xl {
        .modal-dialog {
            .modal-content {
                width: 980px;
            }
        }
    } &.xxl {
        .modal-dialog {
            .modal-content {
                width: 1230px;
            }
        }
    }

    &.transparent-confirmation {
        >div {
            background: rgba(255, 255, 255, 0.54);
        }

        .modal-content {
            background: transparent;
            text-align: center;

            .loading-image {
                width: 80px;
                margin-bottom: 30px;
            }

            .content-section h3 {
                color: #282b3b;
                text-align: center;
                font-family: "Narin-Black";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 44px;
            }
        }
    }

    &.info-modal {
        .modal-heading {
            background: #F5F5F5 !important;
            padding: 60px 15px 60px 15px !important;

            .icon,
            h2 {
                color: #282B3B !important;
            }
        }

        .modal-btn {
            justify-content: space-around;
        }
    }

    &.checkbox-modal {
        .modal-heading {
            background: #F5F5F5 !important;
            padding: 0 15px 20px 15px !important;

            h2 {
                color: #282B3B !important;
                font-weight: bold !important;
                font-size: 18px !important;
            }
        }

        .modal-body {
            padding: 0 12% !important;
        }

        .formlabel {
            text-align: left;
            color: #ADAEB4 !important;
        }

        .modal-footer {
            justify-content: space-around !important;
        }

        .checkbox-click {
            display: flex;
            flex: 1;
            padding-top: 6px;
            padding-bottom: 22px;

            .cs-checkbox {
                margin-left: 0;

                span:after,
                span:before {
                    display: none;
                }

                .Mui-checked+span {
                    background: #282B3B;
                    color: #fff !important;
                    border-radius: 20px;
                    min-width: 48px;
                    padding: 0 !important;
                }

                span:nth-child(1) {
                    margin: 0;
                    width: 0;
                    height: 0;
                }

                span:nth-child(2) {
                    padding: 0;
                    margin-bottom: 0;
                    width: 100%;
                    min-height: 26px;
                    min-width: 48px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                }
            }
        }
    }

    &.leave-detail-modal {
        .modal-dialog .modal-content {
            width: 600px;
        }

        .modal-body {
            overflow-y: auto;
            height: 640px;

            .slot,
            .left {
                font-size: 15px;
                margin-bottom: 8px;
            }

            .slot {
                margin-top: 16px;
                margin-bottom: 6px;
            }

            .grid-datatable {
                width: 400px;
                margin-top: 0;

                .grid-heading {
                    label {
                        font-weight: bold;
                    }

                    label:first-child {
                        max-width: 192px;
                    }
                }

                .grid-heading,
                .grid-data {
                    display: flex;

                    label {
                        flex: 1 1;
                        padding: 8px 10px;
                        font-size: 15px;
                    }
                }

                .grid-data:nth-child(odd) {
                    background: #f9fafb;
                }

                label {
                    font-size: 15px;
                    text-align: center;
                    display: flex;
                    justify-content: center;

                    &:first-child {
                        border-right: 1px solid rgba(180, 181, 187, 0.20);
                    }
                }

                .grid-scroll {
                    overflow-y: auto;
                    height: 300px;
                }
            }
        }
    }

}

.tag-view {
    h6 {
        color: $primary-color;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 8px;
    }

    .box {
        border-radius: 5px;
        background: #FFF;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;

        .tag-item {
            border-radius: 35px;
            background: #ECF2FA;
            float: left;
            padding: 10px 14px 10px 12px;
            margin-right: 10px;
            margin-bottom: 12px;
            display: flex;

            span {
                font-size: 13px;
                width: 100px;
                @include truncate;
            }

            button {
                background: transparent;
                border: none;
                min-width: auto;
                min-height: auto;
            }
        }
    }
}

.react-calendar {
    border: none !important;

    .react-calendar__navigation {
        margin-bottom: 0 !important;
    }

    .react-calendar__month-view__weekdays {
        text-transform: capitalize;
    }

    .react-calendar__month-view__days__day--weekend {
        color: #306efe !important;
    }

    .react-calendar__tile {
        padding: 6px 6.6667px;
        border-radius: 10px;
        font-size: 14px !important;
    }
}

.MuiChip-root.MuiChip-filled {
    margin-right: 6px;
}

.scheduler-modal {
    .modal-dialog .modal-content {
        .modal-body {
            overflow-y: auto;
            height: 70vh;
            .input-icon > button {
                bottom: 5px;
            }
        }

        .modal-footer {
            padding: 16px 20px 30px 20px !important;
        }
    }

    .tag-schedule {
        background: #fff;
        min-height: 120px;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 12px;
        display: inline-table;

        .list {
            border-radius: 40px;
            background: #EAF1F9;
            margin-right: 8px;
            display: flex;
            position: relative;
            padding: 4px;
            min-width: 240px;
            float: left;
            align-items: center;
            margin-bottom: 10px;

            img {
                margin-right: 8px;
                border-radius: 50%;
                width: 32px;
            }

            h5 {
                color: $primary-color;
                padding-right: 38px;
                font-size: 14px;
            }

            button {
                position: absolute;
                right: 16px;
                top: 5px;
                background: transparent;
                border: none;
                min-width: auto;
                padding: 0;
            }
        }
    }
}

.info.cs-modal {
    .modal-dialog .modal-content {
        width: 480px;
    }

    .modal-body {
        text-align: center;
        padding: 20px 16% !important;
        margin-bottom: 10px;

        h4 {
            margin-top: 20px;
            font-size: 18px;
        }

        p {
            margin-bottom: 16px;
        }

        button {
            width: 100%;
            margin-top: 20px;
            font-weight: normal;
        }

        &.confirmation {
            >div {
                padding: 0 40px;
            }

        }
    }
}