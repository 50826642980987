.scheduler-page {

    .btn-left,
    .btn-right {
        border-radius: 50%;
        background: #fff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
        width: 40px;
        height: 40px;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        cursor: pointer;

        &:active,
        &:visited {
            background: #f4f3f3;
        }

        svg {
            width: 10px;
            height: 16px;
        }
    }

    .top-month-bar{
        background: #EAF1F9;
        text-align: center;
        h5{
            font-size: 15px;
            text-transform: uppercase;
        padding:0px 4px ;
        letter-spacing: 1.43px;
        font-weight: bold;
        border-bottom: 2px solid #fff;
        }
    }

    .group-main {
        display: flex;
        background: #EAF1F9;

        &.monthly-view {
            .activity-grid {
                .calender-list ul li {
                    width: 150px;
                    min-width: 150px;

                    .card h6 {
                        min-width: 40px;
                    }
                }

                .event-main ul li {
                    width: 150px;
                    min-width: 150px;

                    .hover {
                        width: 140px !important;
                        left: 4px !important;
                        font-size: 12px !important;
                    }

                    .event {
                        font-size: 11px;
                        position: relative;

                    

                    }

                }
            }

        }

        &.daily-view {
            .activity-grid {
                .calender-list ul li {
                    width: 100%;
                    // .card h6{
                    //     min-width: 40px;

                    // }
                    display: flex;
                    justify-content: center;
                }

                .event-main ul li {
                    width: 100%;

                    .event span {
                        display: inline-block !important;
                    }

                    .hover {
                        display: none;
                    }

                    // .event{
                    //     font-size: 11px;
                    // }
                }
            }

        }

        .user-data {
            h5 {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 76px;
                font-size: 18px;
            }

            ul {
                width: 180px;
                background: #EAF1F9;
                padding-top: 43px;

                li {
                    list-style: none;
                    border-top: 1px solid #fff;
                    background: #EAF1F9;

                    .sch-main {
                        text-align: center;
                        padding:6px 4px;
                        height: 154px;
                        position: relative;
                        display: flex;
    align-items: center;

                        // .delete-btn {
                        //     min-width: auto;
                        //     background: #fff;
                        //     border-radius: 6px;

                        //     width: 33px;
                        //     border: none;
                        //     position: absolute;
                        //     right: 5px;
                        //     top: 5px;
                        //     padding: 0;
                        //     display: flex;
                        //     align-items: center;
                        //     min-height: auto;
                        //     height: 30px;

                        //     svg {
                        //         width: 20px;
                        //         height: 17px;
                        //     }
                        // }

                        a {
                            border-radius: 50%;
                            width: 26px;
                            height: 26px;
                            background: #fff;
                            color: $primary-color;
                            display: inline-flex;
                            font-weight: bold;
                            font-size: 13px;
                            text-align: center;
                            justify-content: center;
                            align-items: center;
                        }

                        img {
                            border-radius: 50%;
                        }

                        .sch-heading {
                            .title {
                                color: $primary-color;
                                font-size: 16px;
                                font-weight: bold;
                                width: 100%;
                                padding: 0 18px;
                            line-height: 20px;
                            }

                            .designation {
                                line-height: 14px;
                                color: $primary-color;
                                font-weight: normal;
                                font-size: 15px;
                            }

                            .activity {
                                margin-top: 0;
                                button {
                                    border-radius: 33px;
                                    background: $primary-color;
                                    padding: 0px 16px;
                                    border: 2px solid $primary-color;
                                    font-size: 13px;
                                    cursor: pointer;
                                    color: #fff;
                                    min-height: auto;
                                    font-weight: normal;
                                }
                                .delete-btn{
                                    border: 2px solid $primary-color;
                                    color: $primary-color;
                                    background: #fff;
                                    svg{
                                        width: 13px;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }



        .activity-grid {
            overflow-x: auto;
            background-color: #fff;
            width: 100%;

            .calender-list {
                ul {
                    display: flex;
                    background: #EAF1F9;

                    li {
                        list-style: none;
                        width: calc(100% / 7);
                        min-height: 44px;
                        border-bottom: 1px solid #fff;
                        border-left: 1px solid #fff;
                        position: relative;

                        .holiday-tag {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            color: #fff;
                            text-align: center;
                            font-size: 11px;
                            background: $primary-color;
                        }

                        .card {
                            display: flex;
                            align-items: end;
                            padding: 6px 6px 2px 6px;
                            min-height: 43px;
                            background: #EAF1F9;

                            h6 {
                                font-weight: bold;
                                font-size: 18px;
                                // min-width: 50px;
                                color: $primary-color;
                                margin-right: 6px;
                                line-height: normal;
                            }

                            span {
                                font-size: 14px;
                            }
                        }

                        &.active {
                            .card {
                                background: $primary-color;
                                color: #fff;

                                h6 {
                                    color: #fff;
                                }
                            }
                        }
                    }

                }
            }

            .event-main {
                ul {
                    display: flex;
                    background: #fff;

                    li {
                        list-style: none;
                        width: calc(100% / 7);
                        border-bottom: 1px solid #EBEBEC;
                        border-right: 1px solid #EBEBEC;
                        position: relative;
                        height: 154px;
                        display: flex;
                        align-items: center;
                        &.leave-box{
                            background: url("../../images/line-bg.jpg") no-repeat;
                            background-size: cover;
                            .leave{
                                background: none;
                            }
                        }
                        .leave-request{
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            // background: rgba(219, 167, 55, 0.358);
                            z-index: 9;
                            text-align: center;
                          
                            font-size: 14px;
                           
                            >div{
                                position:relative;
                                align-items: center;
                                justify-content: center;
                                display: flex;
                                height: 100%;
                            }
                            .bg{
                                width: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                opacity: 0.2;
                                z-index: -1;
                            }
                        }
                        .grey-section {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background: rgb(218 218 218 / 37%);
                            z-index: 9;
                        }
                       

                        .leave {
                            background: url("../../images/line-bg.jpg") no-repeat;
                            background-size: cover;
                            text-align: center;
                            margin-top: 1px;
                            width: 100%;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .hover-main {
                            position: relative;
                        }

                        .hover {
                            position: absolute;
                            border-radius: 2px;
                            background: $primary-color;
                            width: 160px;
                            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
                            left: 10px;
                            top: 80%;
                            font-size: 13px;
                            color: #fff;
                            padding: 4px;
                            text-align: center;
                            z-index: 9;
                        }

                        .event-group {
                            width: 100%;
                            // overflow-y: auto;

                            .event-scroll {
                                width: 100%;
                                height: 154px;
                                padding: 0;
                                .cell{
                                    // background: #efefef;
                                    min-height: 50%;
                                    border-bottom: 1px solid #d2fa91;
                                    
                                }
                                .bar{
                                    height:5px;
                                    width: 100%;
                                    border-radius: 2px;
                                    position: relative;
                                    &.active{
                                       background: #FC0000;
                                    }
                                    &.blank{
                                        visibility: hidden;
                                    }
                                    .hover-bar{
                                        position: absolute;
                                        right: -140px;
                                        bottom: -20px;
                                        width: 100%;
                                        z-index: 9;
                                        display: none;
                                        background: #fff;
                                        &.active{
                                            display: block;
                                        }
                                        .event{
                                            // border-color: #FC0000;
                                            // background: #EA8585;
                                            border-left: 4px solid;
                                            width: 100%;
                                           
                                            padding: 32px 6px;
                                            .bg{
                                                // opacity: 80%;
                                                z-index: -1;
                                            }
                                        }
                                        
                                    }
                                }
                                .leave {
                                    // min-height: 180px;
                                    text-align: center;
                                    width: 100%;
                                    font-size: 14px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 2px;
                                    // border-left: 6px solid #999;
                                    text-align: center;
                                    padding: 30px 6px;
                                    font-size: 13px;
                                    margin-bottom: 2px;
                                    // background: #fafafa;

                                }
                            }
                        }

                        .event {
                            border-radius: 2px;
                            border-left: 8px solid;
                            text-align: left;
                            padding: 2px 2px;
                            // font-size: 13px;
                            margin-bottom: 2px;
                            position: relative;
                            overflow: hidden;
                            .blank-text{
                                visibility: hidden;
                            }
                            &.blank{
                                visibility: hidden;
                            }
                            @media screen and (max-width:1500px) {
                                padding:  2px 1px 2px 2px;
                                border-left: 3px solid;
                            }
                            >div{
                                display: grid;
                            }
                            label{
                                font-weight: bold;
                                font-size: 12px;
                                margin:0 0 3px 0 ;
                                line-height: 14px;
                                letter-spacing: -0.2px;
                                @media screen and (max-width:1500px) {
                                    font-size: 11px;
                                    letter-spacing: -0.4px;
                                }
                            }

                            span {
                                margin: 0 0 0 0;
                                position: relative;
                                font-size: 10px;
                                line-height: 12px;
                                letter-spacing: -0.2px;
                                @media screen and (max-width:1500px) {
                                    font-size: 9px;
                                    letter-spacing: -0.3px;
                                }

                                // &::before {
                                //     content: "|";
                                //     position: absolute;
                                //     left: -8px;
                                //     top: -2px;
                                // }
                            }

                            .bg {
                                position: absolute;
                                border-radius: 2px;
                                height: 120px;
                                opacity: 10%;
                                left: 0;
                                right: 0;
                                top: 0;

                            }

                            &.lunch {
                                >div {
                                    color: #000;
                                }

                            }

                            &.red {
                                background: #FFEDF0;
                                color: #D52649;
                                border-color: #D52649;
                            }

                            &.green {
                                background: #E6FEF4;
                                color: #0CAD6A;
                                border-color: #0CAD6A;
                            }

                            &.yellow {
                                background: #FFF7E6;
                                color: #EFA811;
                                border-color: #EFA811;
                            }

                            &.blue {
                                background: #E2F7FF;
                                color: #38BCF0;
                                border-color: #38BCF0;
                            }

                        }
                    }
                }
            }
        }

    }

    .right-heading {
        display: flex;
        justify-content: end;
        align-items: center;

        .calendar-view {
            margin-left: 16px;

            >button {
                background: #fff;
                border-radius: 30px;
                border-color: #cfcfcf;
                color: $primary-button !important;
                padding: 10px 30px;
                font-size: 16px;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
                font-weight: normal;
                min-width: 200px;
                justify-content: start;
            }
        }

        .cs-dropdown {
            margin-left: 12px;
            min-width: 170px !important;
        }

    }

    .report-heading {
        display: flex;
        align-items: center;

        .radio-hover {
            position: relative;

            .hover-message {
                position: absolute;
                right: 10px;
                display: none;
                bottom: -50px;
                font-size: 13px;
                background: #434343c2;

                border-radius: 6px;
                padding: 4px 8px;
                color: #fff;
                text-align: center;
                z-index: 2;
            }

            .cs-radio:hover~.hover-message {
                display: block;
            }
        }

        .form-group {
            width: 220px;
            margin: 11px 0 0 0;

            .cs-radio span {
                color: $primary-button !important;
            }
        }

        .cs-dropdown {
            // width: 110px;

            .form-select {
                border-radius: 30px;

                >div {
                    color: $primary-button !important;
                }

                &:after {
                    background: url("../../images/resonante/arrow-blue.png") no-repeat;
                    width: 15px;
                    height: 20px;
                    margin-top: 4px;
                    background-size: 100%;
                    margin-right: 10px;

                }
            }

            fieldset {
                border-color: #fff;
                box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
            }
        }
    }

}

.calendar-view {

    position: relative;


    .react-calendar {
        display: inline-table;
        position: absolute;
        right: 0;
        top: 50px;
        width: 360px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
        z-index: 9;
        border-radius: 10px;
        border: 1px solid #ebebeb !important;
    }
}

.scheduler-modal {
    .modal-body {
        min-height: 390px;

        .btn-align {
            margin: 21px 6px 0 0;
        }

        .label-tag {
            border-radius: 40px;
            background: rgba(22, 39, 59, 0.10);
            ;
            display: inline-block;
            padding: 4px 10px 6px 10px;
            margin: 22px 0 0 0;

            span {
                padding: 4px 6px;
                color: $primary-color;
                font-size: 15px;
                border-right: 1px solid #B2B5BA;
                margin-right: 8px;
                padding-right: 12px;

                &:last-child {
                    border-right: none;
                    margin-right: 0;
                    padding-right: 10px;
                }
            }
        }
    }

    .calendar-view {
        .react-calendar {
            top: 68px;
        }
    }
    
}
.range{
    .modal-dialog .modal-content .modal-body{
        height: auto;
        min-height: 460px;
        .calendar-view .react-calendar{
            right: -50px;
            top: 70px;
        }
    }
}

.activity-modal {
    .modal-body {
        height: 460px;
        overflow-y: auto;

        p {
            font-size: 16px !important;
            margin-bottom: 10px;
            width: 100%;
        }

        .calendar-view {
            >button {
                border-radius: 5px;
                background: $primary-button;
                min-width: auto;
                padding: 4px;
                min-height: auto;
                box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);

            }

            .react-calendar {
                top: 40px;
            }
        }

        .color-dots {
            margin-bottom: 20px;
            display: block;
            width: 100%;
            overflow: hidden;

            label {
                float: left;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid;
                margin-right: 10px;

                &.red {
                    background: #D83B5A;
                    border-color: #D83B5A;
                }

                &.yellow {
                    background: #F0B028;
                    border-color: #F0B028;
                }

                &.blue {
                    background: #4BC2F1;
                    border-color: #4BC2F1;
                }

                &.white {
                    background: #fff;
                    border-color: rgba(0, 0, 0, 0.29);
                }
            }
        }

        .event-group {
            width: 100%;
            // overflow-y: auto;
            margin-bottom: 20px;
            min-height: 240px;

            .event-scroll {
                width: 100%;
                // height: 160px;
                padding: 2px 2px 0 2px;
            }
        }

        .event {
            border-radius: 2px;
            border-left: 6px solid;
            text-align: center;
            display: flex;
            justify-content: space-between;
            padding: 3px 10px;
            font-size: 16px;
            margin-bottom: 10px;
            position: relative;
            overflow: hidden;
            align-items: center;

            button {
                position: relative;
                z-index: 1;

                &:hover {
                    background: transparent;
                }
            }

            .bg {
                position: absolute;
                border-radius: 2px;
                height: 60px;
                opacity: 10%;
                left: 0;
                right: 0;
                top: 0;
                z-index: 0;
            }

            button {
                min-width: auto;
                padding: none;

                &:hover {
                    background: none;
                }
            }

            &.red {
                background: #FFEDF0;
                color: #D52649;
                border-color: #D52649;

                button {
                    svg path {
                        fill: #D52649;
                    }
                }
            }

            &.green {
                background: #E6FEF4;
                color: #0CAD6A;
                border-color: #0CAD6A;

                button {
                    svg path {
                        fill: #0CAD6A;
                    }
                }
            }

            &.yellow {
                background: #FFF7E6;
                color: #EFA811;
                border-color: #EFA811;

                button {
                    svg path {
                        fill: #EFA811;
                    }
                }
            }

            &.blue {
                background: #E2F7FF;
                color: #38BCF0;
                border-color: #38BCF0;

                button {
                    svg path {
                        fill: #38BCF0;
                    }
                }
            }

            &.orange {
                background: #FEE8D8;
                color: #E77C35;
                border-color: #E77C35;

                button {
                    svg path {
                        fill: #E77C35;
                    }
                }
            }

        }
    }
}