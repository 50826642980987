.dashboard-main {
    .cs-dropdown-menu {
        button {
            width: 100% !important;
            text-align: left !important;
            justify-content: flex-start !important;
            padding: 12px 14px !important;
            position: relative;
            border-radius: 6px;

            &:after {
                content: "";
                background: url("../../images/icons/expand.png") no-repeat;
                width: 8px;
                height: 14px;
                background-size: 100%;
                position: absolute;
                right: 10px;
                top: 16px;
            }

            &:focus {
                background: #fff !important;
            }

            &:hover {
                background: #282b3b !important;
                color: #fff !important;

                &:after {
                    background: url("../../images/icons/expand-white.png") no-repeat;
                    width: 8px;
                    height: 14px;
                    background-size: 100%;
                }
            }

            img {
                width: auto !important;
            }
        }
    }

    .card-heading {
        color: #282b3b;
        font-size: 26px;
        font-family: "Narin-Medium";
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        text-align: center;
        padding: 14px 0;
        margin-top: 8px;
    }

    .summary-action {
        min-height: 640px;
        border-radius: 20px;
        box-shadow: none;

        @media only screen and (max-width: 1400px) and (min-width: 1279px) {
            min-height: 500px;
        }

        @media only screen and (max-width: 1540px) and (min-width: 1400px) {
            min-height: 515px;
        }

        @media only screen and (max-width: 1780px) and (min-width: 1540px) {
            min-height: 570px;
        }
    }

    .card-layout {
        padding: 0 16px;

        .graph {
            min-height: 550px;
            display: flex;
            align-items: center;
            width: 100%;

            @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                min-height: 440px;
            }

            @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                min-height: 410px;
            }

            @media only screen and (max-width: 1780px) and (min-width: 1540px) {
                min-height: 500px;
            }

            >div {
                width: 100%;
            }

            canvas {
                @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                    min-height: 340px;
                }

                @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                    min-height: 400px;
                }

                @media only screen and (max-width: 1780px) and (min-width: 1541px) {
                    min-height: 440px;
                }
            }
        }

        .progress-bar {
            padding-bottom: 20px;
            padding-right: 70px;
            position: relative;

            .MuiLinearProgress-root {
                height: 18px;
                border-radius: 40px;

                .MuiLinearProgress-barColorPrimary {
                    background: #1c9690;
                }
            }

            .MuiLinearProgress-colorPrimary {
                background: #fff;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
            }
        }

        .progress-bar {
            h5 {
                color: #282b3b;
                font-family: $primary-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                margin: 0;
            }

            h6 {
                color: #282b3b;
                font-family: $primary-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                margin: 0;
                position: absolute;
                right: 30px;
                top: 24px;
            }
        }
    }

    .hits-action {
        padding: 0 10px 16px 10px;
        border-radius: 20px;
        box-shadow: none;

        .progress-bar {
            .MuiLinearProgress-root {
                .MuiLinearProgress-barColorPrimary {
                    background: #1c9690;
                }
            }
        }
    }

    .misses {
        padding: 0 10px 16px 10px;
        border-radius: 20px;
        box-shadow: none;

        .progress-bar {
            .MuiLinearProgress-root {
                .MuiLinearProgress-barColorPrimary {
                    background: #ed5c5c;
                }
            }
        }
    }

    .summary-action {
        .piechart {
            position: relative;

            .chart-align {
                display: flex;
                justify-content: center;
            }

            canvas {
                height: 220px !important;
                width: 220px !important;

                @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                    height: 180px !important;
                    width: 180px !important;
                }

                @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                    height: 180px !important;
                    width: 180px !important;
                }

                @media only screen and (max-width: 1700px) and (min-width: 1541px) {
                    height: 200px !important;
                    width: 200px !important;
                }

                @media only screen and (max-width: 1780px) and (min-width: 1700px) {
                    height: 200px !important;
                    width: 200px !important;
                }
            }
        }

        .chart {
            position: absolute;
            top: 13%;
            width: 100%;
            text-align: center;

            @media only screen and (max-width: 1700px) and (min-width: 1541px) {
                top: 14%;
            }

            h3 {
                text-align: center;
                font-family: "Narin-Medium";
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 33px;
                text-align: center;
                min-width: 140px;

                &.green {
                    color: #1c9690;
                }

                &.red {
                    color: #ed5c5c;
                }

                &.orange {
                    color: #ee9849;
                }

                @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                    font-size: 28px;
                }
            }

            .align {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            .content {
                border-radius: 20px;
                background: #5b58da;
                color: #fff;
                padding: 4px 10px;
                min-width: 140px;
                max-width: 140px;
                font-size: 10px;
                margin-bottom: 8px;
                margin-top: 4px;
                text-align: center;

                @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                    padding: 3px 10px;
                    min-width: 110px;
                    max-width: 120px;
                }

                @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                    padding: 3px 10px;
                    min-width: 110px;
                    max-width: 120px;
                }

                @media only screen and (max-width: 1780px) and (min-width: 1541px) {}
            }
        }

        .grey-heading {
            color: #a2a2a3;
            text-align: center;
            font-family: $primary-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 33px;
            padding-top: 10px;
            padding-bottom: 20px;

            @media only screen and (max-width: 1380px) and (min-width: 768px) {
                font-size: 20px;
            }

            @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                padding-top: 6px;
                font-size: 20px;
                padding-bottom: 6px;
            }

            @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                padding-top: 6px;
                font-size: 20px;
                padding-bottom: 6px;
            }

            @media only screen and (max-width: 1780px) and (min-width: 1541px) {
                padding-bottom: 10px;
                font-size: 22px;
            }
        }

        .feedback-section {
            display: flex;
            padding-bottom: 20px;

            @media only screen and (max-width: 1780px) and (min-width: 1341px) {
                padding-bottom: 10px;
            }

            .feedback {
                flex: 1;
                text-align: center;

                img {
                    width: auto;
                }

                h5 {
                    margin-top: 0px;
                    font-family: "Narin-Medium";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;

                    &.green {
                        color: #1c9690;
                    }

                    &.red {
                        color: #ed5c5c;
                    }

                    &.orange {
                        color: #ee9849;
                    }

                    @media only screen and (max-width: 1380px) and (min-width: 768px) {
                        font-size: 18px;
                        margin-top: 6px;
                    }

                    @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                        font-size: 18px;
                        line-height: 20px;
                    }

                    @media only screen and (max-width: 1780px) and (min-width: 1541px) {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }

                h6 {
                    font-family: "Narin-Medium";
                    font-size: 18px;
                    margin-top: 6px;
                    color: #282b3b;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;

                    @media only screen and (max-width: 1380px) and (min-width: 768px) {
                        font-size: 16px;
                    }

                    @media only screen and (max-width: 1780px) and (min-width: 1341px) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        h3 {
            text-align: center;
            font-family: $primary-font;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 33px;
            padding: 0 0 10px 0;

            strong {
                font-family: "Narin-Black";
            }

            @media screen and (max-width: 767px) {
                font-size: 20px;
            }

            @media only screen and (max-width: 1380px) and (min-width: 768px) {
                font-size: 24px;
                padding: 0;
            }

            @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                font-size: 20px;
                padding: 0;
            }

            @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                font-size: 20px;
                padding: 0;
            }

            @media only screen and (max-width: 1780px) and (min-width: 1541px) {
                font-size: 24px;
                padding: 0;
            }
        }

        .response-section {
            padding: 0 10px 20px 10px;
            display: flex;

            @media only screen and (max-width: 1380px) and (min-width: 768px) {
                padding: 0 8px 20px 8px;
            }

            h5 {
                color: #1c9690;
                text-align: left;
                font-family: $primary-font;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 33px;

                @media screen and (max-width: 767px) {
                    font-size: 15px;
                }

                @media only screen and (max-width: 1380px) and (min-width: 768px) {
                    font-size: 14px;
                }

                @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                    line-height: 20px;
                    font-size: 16px;
                }

                @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                    line-height: 20px;
                    font-size: 16px;
                }
            }

            .box-response {
                text-align: center;
                font-family: "Narin-Black";
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 33px;
                padding: 8px;

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }

                @media only screen and (max-width: 1380px) and (min-width: 768px) {
                    padding: 0;
                    font-size: 16px;
                }

                @media only screen and (max-width: 1400px) and (min-width: 1279px) {
                    padding: 0;
                    font-size: 16px;
                }

                @media only screen and (max-width: 1540px) and (min-width: 1400px) {
                    padding: 0;
                    font-size: 16px;
                }

                @media only screen and (max-width: 1780px) and (min-width: 1541px) {
                    padding: 4px;
                    font-size: 18px;
                }
            }

            .positive {
                flex: 1;

                h5 {
                    color: #1c9690;
                }

                .box-response {
                    border: 1px solid #1c9690;
                    border-radius: 10px 0 0 10px;
                    color: #1c9690;
                    background: #c6f1ef;
                }
            }

            .negative {
                flex: 1;

                h5 {
                    color: #ed5c5c;
                }

                .box-response {
                    border: 1px solid #ed5c5c;
                    background: #ffdbdb;
                    color: #ed5c5c;
                    border-radius: 0 10px 10px 0;
                }
            }
        }
    }

    .MuiGrid-item {
        position: relative;

        .rdrCalendarWrapper {
            position: absolute;
            left: 17px;
            top: 75px;
            border-radius: 10px;
            z-index: 1;

            .rdrMonth {
                width: 100%;
            }
        }
    }
}

.search-menu {
    .MuiMenu-paper {
        min-width: 286px !important;
        margin-left: -10px !important;
        margin-top: 10px !important;
        border-radius: 20px !important;
    }

    ul li {
        width: 100%;
        display: flex;
        padding: 0 15px;

        &:focus,
        &:active,
        &:visited,
        &:hover {
            background: #fff;
        }

        .checkbox-section {
            width: 100%;

            label {
                width: 100%;
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 10px;
            }
        }
    }

    .search-box {
        padding: 0 10px 11px 10px;
    }

    .search-input {
        .search-icon {
            top: 12px;
        }
    }

    .checkbox-section {
        //
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .cs-checkbox span:nth-child(2) {
        padding: 0px 0 0 3px;
        font-weight: normal;
    }
}

.customer-list {
    .grid-card {
        overflow-y: scroll;
        height: 62vh;
        background: #fff;
        border-radius: 4px;
        // box-shadow: 0px 0px 14px 0px rgba(40, 43, 59, 0.25) inset;
        padding: 14px;
        width: 100%;

        .card {
            width: 100%;
            margin-right: 0;
            background: #fff;
            border-radius: 5px;
            display: flex;
            padding: 10px;
            justify-content: flex-start;
            -webkit-box-shadow: 0px 0px 5px 1px rgba(189, 189, 189, 0.52);
            -moz-box-shadow: 0px 0px 5px 1px rgba(189, 189, 189, 0.52);
            box-shadow: 0px 0px 5px 1px rgba(189, 189, 189, 0.52);
            transition: transform 0.3s ease;
            &.active{
                outline:2px solid $primary-color;
                transform: scale(1.01);
                background: #18263c08;
            }
            .thumbnail {
                width: 110px;
                height: 90px;
                // background: #efefef;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                margin-right: 20px;
                border-radius: 10px;
                overflow: hidden;
                background: #fff;
                border: 1px solid #efefef;

                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                }
            }

            .card-heading {
                position: relative;
                display: flex;
                justify-content: space-between;
                min-height: 50px;
                align-items: flex-start;

                h3 {
                    font-size: 18px;
                    // width: 260px;

                }

                .delete {
                    min-width: auto;
                    margin-right: 10px;
                    padding: 3px 0 0 0;
                    min-height: auto;
                }
            }

            .card-body {
                padding: 0;
                text-align: center;
                display: flex;
            }

        }
    }

    .group-main.image {
        img {
            width: 100%;
        }
    }
}

.regions-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    align-items: center;
    padding: 3px 10px;

    svg {
        margin-right: 8px;
    }

    span {
        font-size: 16px;
    }

    .btn-group {
        display: flex;

        button {
            font-size: 14px;
            min-width: auto;

            img {
                width: 20px;
            }

            &.delete {
                margin-left: 16px;
            }
        }
    }

}

.customer-modal {
    .modal-body {
        overflow-y: auto;
        height: 480px;

        .cs-dropdown {
            background: #fff;
        }
    }

    .region-list {
        width: 100%;

        span {
            padding: 6px 12px;
            border-radius: 6px;
            background: #DFEBF9;
            // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
            float: left;
            margin-right: 10px;
            margin-bottom: 10px;
            align-items: center;
            min-width: 200px;
            font-size: 15px;
            padding-right: 60px;
            position: relative;

            button {
                background: transparent;
                border: none;
                position: absolute;
                right: 12px;
                top: 12px;
                cursor: pointer;
                z-index: 9;
            }
        }

        >ul>li {
            margin-bottom: 10px;
            list-style: none;

            .list-item {
                padding: 6px 12px;
                border-radius: 5px;
                background: #DFEBF9;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
                display: flex;
                align-items: center;

                label {
                    min-width: 280px;
                    font-size: 16px;
                }

                button {
                    padding: 0;
                    background: transparent;
                    min-width: auto;
                    min-height: auto;
                }

                .calendar {
                    box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
                }

                .plus-btn {
                    margin-left: 20px;
                    background: transparent;
                    color: $primary-button;
                    font-weight: normal;
                    font-size: 14px;
                }
            }

            .list-detail {
                background: #DFEBF9;

                li {
                    list-style: none;
                    border-top: 1px solid #cbdffa;
                    padding: 8px 12px;

                    span {
                        font-size: 14px !important;
                    }

                }
            }

        }
    }
}