* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: #fff;
  scroll-behavior: smooth;
  font-family: $primary-font !important;

}
body{
  color: $primary-color !important;
}
body,
button {
  font-family: $primary-font !important;
}
// body{
//   background: #F5F5F5;
// }
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $primary-font !important;
  color: #282B3B;
}

label,
span {
  font-family: $primary-font !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {}

img {
  width: 100%;
  height: auto;
}

button {
  text-transform: capitalize !important;
}

.css-nkchhw {
  @media screen and (max-width: 767px) {
    padding: 0 !important;
  }
}



.coming-soon {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 48px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 22px;
    color: #a3a3a3;
  }
}


/* for mobile */

@media only screen and (max-width: 767px) {}

@media (min-width: 767px) and (max-width: 991px) {}