@font-face {
    font-family: 'Narin-Black';
    src: url('../../fonts/NarinBlack.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/NarinBlack.eot?#iefix') format('embedded-opentype'),
     /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/NarinBlack.woff2') format('woff2'),
         url('../../fonts/NarinBlack.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/NarinBlack.svg#Narin') format('svg');
}

@font-face {
    font-family: 'Narin-Regular';
    src: url('../../fonts/NarinRegular.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/NarinRegular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/NarinRegular.woff2') format('woff2'),
         url('../../fonts/NarinRegular.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/NarinRegular.svg#Narin') format('svg');
}
@font-face {
    font-family: 'Narin-Medium';
    src: url('../../fonts/NarinMedium.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/NarinMedium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/NarinMedium.woff2') format('woff2'),
         url('../../fonts/NarinMedium.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/NarinMedium.svg#Narin') format('svg');
}
@font-face {
    font-family: 'Samsung-Sans-Regular';
    src: url('../../fonts/samsungsharpsans.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/samsungsharpsans.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/samsungsharpsans.woff2') format('woff2'),
         url('../../fonts/samsungsharpsans.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/samsungsharpsans.svg#Samsung') format('svg');
}
@font-face {
    font-family: 'Samsung-Sans-Medium';
    src: url('../../fonts/samsungsharpsans-medium.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/samsungsharpsans-medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/samsungsharpsans-medium.woff2') format('woff2'),
         url('../../fonts/samsungsharpsans-medium.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/samsungsharpsans-medium.svg#Samsung') format('svg');
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../../fonts/Gotham-Book.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
     /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/Gotham-Book.woff2') format('woff2'),
         url('../../fonts/Gotham-Book.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/Gotham-Book.svg') format('svg');
}

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../../fonts/Gotham-Bold.eot');
    /* IE9 Compat Modes */
    src: url('../../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
        /* Super Modern Browsers */
         url('../../fonts/Gotham-Bold.woff2') format('woff2'),
         url('../../fonts/Gotham-Bold.woff') format('woff'),
         /* Safari, Android, iOS */
         url('../../fonts/Gotham-Bold.svg#') format('svg');
}