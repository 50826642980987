.hide-xs {
  display: none !important;
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xl-container {
  @media screen and (min-width: 1700px) {
    padding-right: 7%;
  }

  @media screen and (min-width: 2000px) {
    max-width: 90%;
  }
}

.card-layout {
  min-height: 500px;
}

.material-icons-round {
  font-family: "Material Icons Round" !important;
  font-weight: normal;
}

.main-container {
  padding-top: 70px !important;
  min-height: 100vh;

  @media screen and (max-width: 767px) {
    padding: 54px 10px !important;
  }

  @media only screen and (max-width: 1200px) and (min-width: 768px) {
    padding: 54px 16px !important;
  }
}

.fl-remove fieldset {
  top: 0;
}
.disabled,
button:disabled {
  opacity: 0.3 !important;
  cursor: default;
}

.input-icon {
  position: relative;

  >button {
    background: transparent;
    min-width: auto;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 26px;
  }
}

.rdrCalendarWrapper {
  position: absolute;
  right: 0;
  z-index: 9;
  top: 71px;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
}


.formlabel span,
.formlabel {
  font-family: $primary-font !important;
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 500 !important;
  padding-bottom: 5px !important;
  flex: 1;
  align-items: center;
  display: flex;
  overflow: inherit !important;
  min-height: 22px;

  @media screen and (max-width: 1200px) {
    font-size: 14px !important;
  }
}

.formlabel span {
  padding-bottom: 3px;
}

.PhoneInput.forminput {
  border: 1px solid #cfcfcf;

  input {
    border: none;
    border-radius: none;
    outline: none;
  }
}

.form-select,
.forminput input,
.forminput {
  width: 100%;
  border-color: #cfcfcf;
  border-radius: 8px;
  min-height: 40px;
  background: #fff !important;
  font-size: 14px;
  color: $primary-color;
  padding: 3px 8px;
  line-height: 24px;
  font-family: $primary-font !important;

  &.css-b62m3t-container {
    padding: 3px 0;
  }

  &.error {
    fieldset {
      border-color: red;
    }
  }

  &:disabled {
    background-color: #f3f3f3ee !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.6)
  }
  .Mui-disabled fieldset{
    border-color: rgb(0 0 0 / 14%);
  }
}
.cs-input{
  border-color: #cfcfcf;
  border-radius: 8px;
  min-height: 40px;
  background: #fff !important;
  padding: 5px 9px;
}
textarea {
  &:focus {
    outline: 2px solid #3169e7;
    outline-offset: -2px;
    border-color: #cfcfcf;
  }
}

.form-select {
  min-height: 44px !important;
}



.page-heading {
  h5 {
    margin: 0 0 8px 0;
    color: $primary-color;
    font-size: 16px;
    font-weight: 700;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    min-width: auto;
    min-height: auto;

    &:hover {
      background: transparent !important;
    }
  }
}

.search-input {
  position: relative;
  width: 100%;
  z-index: 0;

  .forminput {
    background: transparent !important;

    >div:hover {
      border: none !important;

      fieldset {
        border: none !important;
      }
    }
  }

  .search-icon {
    position: absolute;
    right: 16px;
    top: 9px;
    z-index: 1;
  }

  input {
    padding: 2px 38px 2px 14px !important;
    border-radius: 35px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
    border-color: #fff;
  }

  fieldset {
    border-color: #fff;
    border-radius: 35px;
  }
}

.upload-image {
  .upload-btn {
    width: 100%;
    height: 163px;
    padding: 0 !important;
    border-radius: 6px;
    display: block;
    background: #fff;
    border: 1px solid #cfcfcf;
    overflow: hidden;
img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
    svg {
      fill: $primary-color;
      margin-bottom: 10px;
    }

    span {
      justify-content: center;
      width: 100%;
      color: #a5a6ad;
      text-align: center;
      font-family: $primary-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      display: flex;
    }
  }
}

.menu-main {
  width: 200px;
  padding: 12px 10px 10px 6px;

  .MuiMenu-paper {
    background: transparent !important;
    box-shadow: none !important;
    max-width: calc(100% - 25px);
  }

  ul {
    background: #fff;
    padding: 14px 0;
    border-radius: 10px;
    box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1),
      0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
      position: absolute;
      left: 40px;
      top: -7px;
      z-index: 9;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    li {
      border-radius: 0;
      color: #282b3b;
      padding: 8px 10px;
      justify-content: flex-start;
    }
  }
}