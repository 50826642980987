// Header Code
header.main-header {
    background-color: $primary-color !important;
    border-radius: 0;
    top: 0;

    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    //     0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    @media screen and (max-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 70px;
        flex-direction: row;

        >div {
            width: 100%;
        }

        .menu-mobile {
            padding-left: 0;
        }
    }

    @media screen and (min-width: 1200px) {

        .menu-mobile {
            display: none;
        }
    }

    @media screen and (max-width: 1200px) {
        padding-top: 0;
        padding-bottom: 0;

        .top-header {
            width: 100%;
        }
    }

    .brand {
        padding-left: 18px;
        width: 230px;

        @media screen and (max-width: 1200px) {
            display: none;
        }
    }

    .item-main {
        display: flex;

        @media screen and (max-width: 1200px) {
            width: 100%;
            justify-content: space-between;
        }


        .web {
            align-items: center;
            display: flex;
        }
    }

    @media screen and (max-width: 1200px) {
        button>span {
            font-size: 35px !important;
        }
    }

    .header-item {
        div {
            display: flex;
            align-items: center;
            cursor: pointer;
    
            img {
                width: 30px;
            }
    
            label {
                text-align: left;
                padding-left: 8px;
                width: 120px;
                cursor: pointer;
                color: #fff;
                font-size: 15px;
                line-height: 17px;
            }
    
            .arrow-right {
                content: "";
                background: url(../../images/resonante/right-white.png) no-repeat;
                background-size: contain;
                position: absolute;
                right: 12px;
                top: 14px;
                width: 8px;
                height: 15px;
                color: #fff;
                transition: all 0.2s ease-in;
            }
        }
        &.profile .arrow-right{
            display: none;
        }
    }

    .header-item.clinic-select {
        background: #fff;
        border-radius: 30px;
        margin-right: 26px;
        width: 380px;

        label {
            color: $primary-color;
            font-weight: bold;
            width: 360px;
            padding: 0;
        }

        .arrow-right {
            background: url(../../images/resonante/nav-arrow.png) no-repeat;
        }
    }

    .language {
        margin-right: 28px;
        border: 1px solid #fff;
        border-radius: 20px;
        min-width: 210px;
        padding: 10px 10px;
        justify-content: flex-start;

        img {
            width: 21px !important;
            height: 21px !important;
            border-radius: 50%;
        }

        @media screen and (max-width: 767px) {
            border: none;
            min-width: auto;
            margin-right: 10px;

            label {
                display: none;
            }

            .arrow-right {
                display: none;
            }

            img {
                width: 24px !important;
            }
        }
        &.customer-list{
            min-width: 380px;
            div label{
                width: 370px !important;
            }
        }
    }
  

    .select-brand {
        margin-right: 28px;
        background: #465163;
        border-radius: 20px;
        padding: 6px 12px;
        border: 1px solid #465163;

        label {
            color: $primary-color !important;
        }

        img {
            width: 26px !important;
        }

        @media screen and (max-width: 767px) {

            min-width: auto;
            margin-right: 10px;
        }
    }
}

.language-menu {
    .MuiMenu-paper {
        background: transparent !important;
        box-shadow: none !important;
    }

    ul {
        background: #fff;
        padding: 14px 0;
        border-radius: 10px;
        box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1),
            0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
            position: absolute;
            left: 40px;
            top: -7px;
            z-index: 9;
        }

        li {
            border-radius: 0;
            color: #282b3b;
            padding: 8px 10px;
        }
    }
}

.MuiDrawer-docked.sidenav-root {
    >div {
        @media screen and (max-width: 1200px) {
            margin: 80px 16px 16px 10px !important;
        }
    }

    .sidenav-main {
        color: #434343;
        z-index: 9;
        position: relative;

        .sidenav-logo {
            display: none;

            @media screen and (max-width: 1200px) {
                margin-bottom: 10px;
                padding-bottom: 10px;
                display: block;

                img {
                    width: 60%;

                }
            }
        }
    }

    >div {
        padding: 18px 0px 18px 12px;
    }

    ul {
        a {
            border-radius: 0;
            box-shadow: none;
            color: $primary-color;

            li .MuiListItemText-root span {
                font-family: $primary-font !important;
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                color: $primary-color;
            }

            &.active li .side-list-item {
                background: #F5F5F5;
                border-color: $primary-color;

                span {
                    font-family: $primary-font-bold !important;
                }

                box-shadow: none;
            }
        }

        li {
            margin-bottom: 8px;

            .side-list-item {
                padding: 12px 14px;
                margin: 0;
                height: 44px;
                border-radius: 0 !important;
                border-right: 10px solid #fff;
                color: $primary-color;
                font-family: $primary-font;

                &:hover,
                &:focus {
                    // span{
                    //     font-family: $primary-font-bold !important;
                    // }
                }
            }

            >div>div .MuiListItemIcon-root span {
                background-size: 100% !important;
                width: 20px;
                height: 20px;
                font-size: 0 !important;
            }

            .accordion-summary .MuiAccordionSummary-content .MuiListItemIcon-root span {
                background-size: 100% !important;
                width: 20px;
                height: 20px;
                font-size: 0 !important;
            }

            .customer {
                span {
                    background: url("../../images/resonante/customer.png") no-repeat;
                }
            }

            .configuration {
                span {
                    background: url("../../images/resonante/configuration.png") no-repeat;
                }
            }

            .schedule {
                span {
                    background: url("../../images/resonante/schedule.png") no-repeat;
                }
            }

            .report {
                span {
                    background: url("../../images/resonante/report.png") no-repeat;
                }
            }

            .help {
                span {
                    background: url("../../images/resonante/help.png") no-repeat;
                }
            }

            .privacy {
                span {
                    background: url("../../images/resonante/privacy.png") no-repeat;
                }
            }
            .leave {
                span {
                    background: url("../../images/resonante/leave.png") no-repeat;
                }
            }
        }

        &.active li {
            .dashboard {
                span {
                    background: url("../../images/icons/dashboard-active.png") no-repeat;
                }
            }
        }

        .nav-main {
            padding: 0;
            margin-bottom: 8px;

            li {
                opacity: 1;
                background: transparent;
                border-radius: none;
                box-shadow: none;
                background: transparent;
                color: $primary-color;
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0;
                margin: 0;
                border-radius: 0.375rem;

                .nav-heading {
                    cursor: pointer;

                    span {

                        font-family: $primary-font !important;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: normal;
                        color: $primary-color;
                    }
                }

                .down-menu {
                    display: grid;
                    padding: 0 0px 0 22px;
                    border-left: 1px solid $primary-color;

                    a {

                        font-size: 16px;
                        font-weight: 500;
                        padding: 0;
                        width: 100%;
                        color: #ffffff;

                        &:last-child {
                            li>div {
                                margin: 0;
                            }
                        }

                        &.active {
                            li>div {
                                box-shadow: none;
                                border-right: 10px solid $primary-color;
                                color: $primary-color;
                                background-color: #F5F5F5;
                                
                            }
                            span{
                                font-weight: bold;
                            }
                        }
                    }

                    li>div {
                        padding: 12px 0;
                        margin: 0 0 6px 0;
                        border-radius: 0;
                       
                        span{
                            width:210px;
                            @include truncate;
                        }
                       
                       
                    }
                }
            }

            .accordion-link {
                background: transparent;
                border-radius: 0;
                box-shadow: none;
                width: 100%;

                .MuiCollapse-root {
                    padding-left: 28px;
                }

                .accordion-summary {
                    padding: 10px 13px 10px 13px;
                    margin: 0;
                    height: 40px;
                    border-radius: 0 !important;
                    border-right: 10px solid #fff;
                    color: $primary-color;
                    min-height: auto !important;

                    &:after {
                        content: "";
                        background: url(../../images/resonante/nav-arrow.png) no-repeat;
                        background-size: contain;
                        position: absolute;
                        right: 0px;
                        top: 14px;
                        width: 15px;
                        height: 15px;
                        color: #fff;
                        transition: all 0.2s ease-in;
                    }

                    >div {
                        align-items: center;
                        margin: 0;
                    }

                    &.Mui-expanded {
                        &:after {
                            transform: rotate(90deg);
                            right: 3px;
                            top: 17px;
                        }
                    }
                }
            }
        }
    }
}

.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    @media screen and (max-width: 767px) {
        width: 16.625rem;
    }
}